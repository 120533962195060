import { Backdrop, Box, Stack, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'
import React from 'react'
import styled from 'styled-components'

export interface OverlayProps {
  loading: boolean
}
const Overlay = styled.div<OverlayProps>`
  display: ${({ loading }) => (loading ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 9999;
`

const Text = styled.div`
  display: flex;
  margin-left: 10px;
  margin-top: 10px;
  color: white;
  text-align: center;
`
interface LoadingComponentProps {
  loading: boolean
  text?: string
}

const LoadingComponent: React.FC<LoadingComponentProps> = ({ loading, text }) => (
  <Overlay loading={loading}>
    <CircularProgress style={{ color: 'white' }} />
    {text && <Text>{text}</Text>}
  </Overlay>
)

export default LoadingComponent

export const LinearLoadingBackdrop = ({
  isOpen,
  backdropTitle,
  currentItem,
  currentCount,
  totalCount,
}: {
  isOpen: boolean
  backdropTitle: string
  currentItem: string
  currentCount: number
  totalCount: number
}) => {
  return (
    <Backdrop
      open={isOpen}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
    >
      <Stack direction="column" alignItems="center" spacing={2} sx={{ width: '300px' }}>
        <Typography
          variant="h6"
          sx={{ color: 'white', display: 'flex', alignItems: 'center', gap: '10px' }}
        >
          {backdropTitle}
          <CircularProgress
            size={20}
            thickness={6}
            sx={{
              color: 'white',
            }}
          />
        </Typography>
        <Box width="100%">
          <LinearProgress
            variant="determinate"
            value={(currentCount / totalCount) * 100}
            sx={{
              height: '10px',
              borderRadius: '5px',
              backgroundColor: 'white',
              '& .MuiLinearProgress-bar': {
                borderRadius: '5px',
                backgroundColor: '#1565c0',
              },
            }}
          />
        </Box>
        <Typography variant="body1" sx={{ color: 'white', textAlign: 'center' }}>
          {currentItem}
          <br />
          {currentCount} de {totalCount} {`(${((currentCount / totalCount) * 100).toFixed(2)}%)`}
        </Typography>
      </Stack>
    </Backdrop>
  )
}
