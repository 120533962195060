import atlas from 'azure-maps-control'
import { AzureMapDataSourceProvider, AzureMapLayerProvider } from 'react-azure-maps'

export default function SubparksDataSource({
  data,
}: Readonly<{ data: null | atlas.data.FeatureCollection }>) {
  if (!data) return null

  return (
    <AzureMapDataSourceProvider id="subparks" collection={data}>
      <AzureMapLayerProvider
        id="subparks-label"
        options={{
          iconOptions: {
            image: 'pin-blue',
            rotationAlignment: 'viewport',
          },
          textOptions: {
            textField: ['get', 'subparkName'],
            textFont: ['Open Sans SemiBold'],
            textSize: 13,
            textAnchor: 'center',
            textJustify: 'center',
            textAllowOverlap: true,
            ignorePlacement: true,
            color: '#ffffff',
            padding: 10,
            rotationAlignment: 'viewport',
          },
          minZoom: 13,
          maxZoom: 15,
        }}
        type="SymbolLayer"
      />
    </AzureMapDataSourceProvider>
  )
}
