import { NoPhotography } from '@mui/icons-material'
import { Box, Pagination, Tooltip, Typography } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import Icon from '~/assets/icons'
import { MaterialIcon } from '~/assets/icons/materialIcons'
import Button from '~/components/Button'
import InputSearch from '~/components/InputSearch'
import ModalCustom from '~/components/ModalCustom'
import ToastNotification from '~/components/ToastNotification'
import ViewError from '~/components/ViewError'
import ViewLoading from '~/components/ViewLoading'
import { useAuth } from '~/contexts/Auth'
import BaseLayout from '~/Layouts/BaseLayout'
import { getCompanySimple } from '~/services/CompaniesAndBranches'
import { deleteSite, getSites } from '~/services/Site'
import { ImageProps } from '~/services/Site/types'
import { theme } from '~/styles/theme'
import * as S from './styles'
interface ISite {
  id: string
  coverImageUrl?: string
  coverImage: ImageProps
  name: string
  lastInspectionDate: string
  local: string
}

type FilterOption = { label: string; value: string } | null

export enum SiteSortingOrder {
  Alphabetic = 0,
  LastInspectionNewestFirst = 1,
  LastInspectionOldestFirst = 2,
  FirstInspectionNewestFirst = 3,
  FirstInspectionOldestFirst = 4,
}

export default function Sites() {
  const navigate = useNavigate()
  const { user } = useAuth()
  const { t } = useTranslation()

  const [siteId, setSiteId] = useState('')
  const [companyOptions, setCompanyOptions] = useState<any[]>([])
  const [companySelected, setCompanySelected] = useState<any>(null)
  const [sites, setSites] = useState<ISite[]>([])
  const [nameOrLocal, setNameOrLocal] = useState('')
  const [filterSelected, setFilterSelected] = useState<FilterOption>(null)
  const [totalPages, setTotalPages] = useState<number | undefined>(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const bottomInputStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      flex: 1,
      minWidth: 300,
      height: 40,
      borderColor: state.isFocused ? theme.colors.primary : theme.colors.blueRGBA3,
      boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(32, 168, 216, 0.25)' : '',
      cursor: 'pointer',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      padding: '8px',
    }),
  }

  const loadCompanies = async () => {
    setLoading(true)
    try {
      const response = await getCompanySimple({
        OnlyHeadOffices: false,
        ConsiderUserCompany: true,
        ReturnOnlyInspectorCompanies: false,
      })
      if (response.success) {
        setCompanyOptions(
          response.data.map((company) => ({ label: company.fantasyName, value: company.id })),
        )
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
    }
    setLoading(false)
  }

  const loadSites = async (
    page: number = 1,
    clear: boolean = false,
    filter?: string,
    companyId: string = '',
  ) => {
    setLoading(true)
    try {
      if (clear) {
        setSites([])
      }

      const paginationAddresses = {
        page,
        pageSize: 18,
        NameOrLocal: nameOrLocal,
        companyId: companyId || companySelected?.value || '',
        filter: filter ?? filterSelected?.value,
      }

      const response = await getSites(paginationAddresses)
      if (response.success) {
        setCurrentPage(page)
        if (page === 1 && response.pages) {
          setTotalPages(response.pages)
        }
        setSites(response.data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
    }
    setLoading(false)
  }

  const handleDeletarSite = (siteIdId: string) => {
    setSiteId(siteIdId)
    setOpenDeleteModal(true)
  }

  const handleDeleteSite = async () => {
    setLoading(true)
    try {
      const response = await deleteSite(siteId)
      if (response.success) {
        loadSites(1, true)
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Parque solar excluído com sucesso!'),
        })
        setOpenDeleteModal(false)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível excluir o site, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível excluir o site, tente novamente mais tarde.'),
      })
    }
    setLoading(false)
  }

  type SelectOption = { label: string; value: string }

  const sortingOptions: SelectOption[] = [
    { label: t('Alfabético'), value: SiteSortingOrder.Alphabetic.toString() },
    {
      label: t('Inspeção mais recente'),
      value: SiteSortingOrder.LastInspectionNewestFirst.toString(),
    },
    {
      label: t('Inspeção mais antiga'),
      value: SiteSortingOrder.LastInspectionOldestFirst.toString(),
    },
  ]

  useEffect(() => {
    loadCompanies()
    loadSites(1, true)
  }, [])

  return (
    <BaseLayout
      title={t('Parques solares')}
      extraContent={
        (user?.accessLevel === 1 || user?.accessLevel === 2) && (
          <Tooltip title={t('Cadastrar Parque solar')}>
            <Box>
              <Button
                iconStart={<Icon name="Add" fill="white" />}
                type="button"
                onClick={() => navigate(`/sites/criar`)}
                label={t('Cadastrar Parque solar')}
                style={{ marginTop: 0, marginLeft: '24px', width: '230px' }}
              />
            </Box>
          </Tooltip>
        )
      }
    >
      <S.FlexWrapper>
        <div
          style={{
            width: '80%',
            margin: '20px auto',
            display: 'flex',
            gap: '10px',
            flexDirection: 'column',
          }}
        >
          <div style={{ display: 'flex', gap: '10px' }}>
            <InputSearch
              placeholder={t('Pesquise por nome ou localização') ?? 'Pesquise por nome ou localização.'}
              value={nameOrLocal}
              onChange={(e) => setNameOrLocal(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  loadSites(1, true)
                }
              }}
              variant="outlined"
              fullWidth
            />
            <Button
              type="button"
              onClick={() => loadSites(1, true)}
              label={t('Pesquisar')}
              style={{ marginTop: 0, width: '140px' }}
            />
          </div>
          <div style={{ display: 'grid', gap: '10px', gridTemplateColumns: '1fr 1fr' }}>
            <Select
              menuPosition="fixed"
              name={t('Selecionar a Empresa...') ?? 'Selecionar a Empresa...'}
              placeholder={t('Selecionar a Empresa...') ?? 'Selecionar a Empresa...'}
              styles={bottomInputStyles}
              noOptionsMessage={() => t('Nenhuma opção disponível')}
              value={companySelected}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setCompanySelected(selectedOption)
                  loadSites(1, true, '', selectedOption.value)
                } else {
                  setCompanySelected(null)
                  loadSites(1, true)
                }
              }}
              options={companyOptions}
              isClearable={true}
            />
            <Select
              menuPosition="fixed"
              name={t('Ordenar por...') ?? 'Ordenar por...'}
              placeholder={t('Ordenar por...') ?? 'Ordenar por...'}
              styles={bottomInputStyles}
              value={filterSelected}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setFilterSelected(selectedOption)
                  loadSites(1, true, selectedOption.value)
                } else {
                  setFilterSelected(null)
                  loadSites(1, true)
                }
              }}
              options={sortingOptions}
              isClearable={true}
            />
          </div>
        </div>
        {loading ? (
          <ViewLoading />
        ) : (
          <S.ContainerSites>
            {sites.length > 0 ? (
              <S.Container>
                <S.SitesGrid>
                  {sites.map((site) => (
                    <S.ContentBoxSolar key={site.id}>
                      <Link to={`/sites/inspections/${site.id}`}>
                        {site?.coverImageUrl ? (
                          <img src={site.coverImageUrl} alt="Painel solar" />
                        ) : (
                          <NoPhotography className="noPhotography" />
                        )}
                      </Link>

                      <div
                        className="contentData"
                        style={{
                          padding: '6px 12px',
                        }}
                      >
                        <h6>{site.name}</h6>
                        <span>{site.local}</span>

                        <p style={{ marginTop: 10, fontStyle: 'italic', color: 'gray' }}>
                          <span
                            style={{
                              marginRight: 3,
                              fontWeight: 'bold',
                            }}
                          >
                            {t('Última inspeção: ')}
                          </span>
                          {site.lastInspectionDate
                            ? moment(site.lastInspectionDate).format('DD/MM/YYYY')
                            : t('Não Realizada')}
                        </p>

                        <div
                          className="flex-content"
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          {(user?.accessLevel === 1 || user?.accessLevel === 2) && (
                            <>
                              <Button
                                type="button"
                                onClick={() => {
                                  setSiteId(site.id)
                                  navigate(`/upload-images/${site.id}`)
                                }}
                                label={t('Criar Inspeção')}
                                style={{ marginTop: '3%' }}
                              />
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '10px',
                                  marginTop: '3%',
                                }}
                              >
                                <Link to={`/sites/editar/${site.id}`}>
                                  <MaterialIcon name="Edit" title={t('Editar Parque solar')} />
                                </Link>
                                <MaterialIcon
                                  name="Delete"
                                  title={t('Deletar Parque solar')}
                                  onClick={() => handleDeletarSite(site.id)}
                                  aria-label="Deletar Site"
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                />
                              </div>
                            </>
                          )}
                          {user?.accessLevel !== 1 && user?.accessLevel !== 2 && (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                marginBottom: '10px',
                              }}
                            >
                              <Link to={`/sites/editar/${site.id}`}>
                                <Tooltip title="Visualizar Parque solar">
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <Icon name="Visibility" fill="rgb(12, 74, 125)" height={20} />
                                    <Typography sx={{ margin: 0, padding: 0 }}>Visualizar</Typography>
                                  </div>
                                </Tooltip>
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    </S.ContentBoxSolar>
                  ))}
                </S.SitesGrid>
                <S.Pages>
                  <Pagination
                    showFirstButton
                    showLastButton
                    count={totalPages}
                    page={currentPage}
                    color="primary"
                    onChange={(e, p) => {
                      e.preventDefault()
                      e.stopPropagation()
                      loadSites(p)
                    }}
                  />
                </S.Pages>
              </S.Container>
            ) : (
              <ViewError message={t('Nenhum parque solar cadastrado!')} />
            )}
          </S.ContainerSites>
        )}

        <ModalCustom
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          onClick={() => {
            handleDeleteSite()
          }}
          loading={loading}
          title={t('Excluir Parque solar')}
          description={t(
            'Tem certeza de que deseja excluir o Parque solar? Após a confirmação, não há como voltar atrás.',
          )}
          confirmationButtonText={t('EXCLUIR')}
          textDeclineButton={t('CANCELAR')}
        />
      </S.FlexWrapper>
    </BaseLayout>
  )
}
