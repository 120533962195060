import atlas from 'azure-maps-control'
import { useContext, useEffect, useId, useState } from 'react'
import {
  AzureMapDataSourceProvider,
  AzureMapLayerProvider,
  AzureMapFeature,
  IAzureMapLayerEvent,
  IAzureMapsContextProps,
  AzureMapsContext,
  AzureMapPopup,
  AzureMapHtmlMarker,
} from 'react-azure-maps'
import { MarkerContainer } from './styles'

type CustomAzureMapMarkerProps = {
  latitude: number
  longitude: number
  iconImage?: string
  size?: number
  symbolLayerOptions?: atlas.Options | atlas.SymbolLayerOptions

  tooltip?: string
  onClick?: () => void
  color?: string
}

export default function CustomAzureMapMarker({
  latitude,
  longitude,
  iconImage = 'pin-blue',
  size = 1.5,
  symbolLayerOptions = {},
  tooltip = '',
  onClick,
  color,
}: Readonly<CustomAzureMapMarkerProps>) {
  const id = useId()
  const { mapRef } = useContext<IAzureMapsContextProps>(AzureMapsContext)
  const [showTooltip, setShowTooltip] = useState(false)

  const layerEvents: Partial<IAzureMapLayerEvent> = {
    mousemove: () => {
      if (mapRef) {
        mapRef.getCanvasContainer().style.cursor = 'pointer'
      }
      setShowTooltip(() => true)
    },
    click() {
      onClick?.()
    },
  }

  const mouseLeaveLayer = () => {
    if (mapRef) {
      mapRef.getCanvasContainer().style.cursor = ''
    }
    setShowTooltip(false)
  }

  useEffect(() => {
    if (mapRef) {
      mapRef.events.add('mousemove', mouseLeaveLayer)

      return () => {
        mapRef.events.remove('mousemove', mouseLeaveLayer)
      }
    }
  }, [mapRef])

  return (
    <AzureMapDataSourceProvider id={id}>
      {color ? (
        <AzureMapHtmlMarker
          options={{
            position: [longitude, latitude],
            color,
          }}
          markerContent={
            <MarkerContainer bgColor={color}>
              <div className="pin"></div>
            </MarkerContainer>
          }
          events={
            Object.entries(layerEvents).map(([eventName, callback]) => {
              return {
                callback,
                eventName,
              }
            }) as any
          }
        />
      ) : (
        <AzureMapLayerProvider
          id={id}
          type="SymbolLayer"
          events={layerEvents}
          options={{
            iconOptions: {
              image: iconImage,
              size,
              allowOverlap: true,
              ignorePlacement: true,
              rotationAlignment: 'viewport',
            },
            ...symbolLayerOptions,
          }}
        />
      )}

      <AzureMapFeature type="Point" coordinate={[longitude, latitude]} />
      <AzureMapPopup
        isVisible={tooltip !== '' && showTooltip}
        options={{
          position: [longitude, latitude],
          closeButton: false,
          showPointer: true,
        }}
        popupContent={<strong style={{ padding: '10px', textAlign: 'center' }}>{tooltip}</strong>}
      />
    </AzureMapDataSourceProvider>
  )
}
