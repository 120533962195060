import { useCallback } from 'react'
import { deleteAnomalyByTypeService } from '~/services/Anomaly'
import ToastNotification from '~/components/ToastNotification'

interface UseAnomalyDeletionByTypeProps {
  onDeletionSuccess: () => void
  onDeletionError?: () => JSX.Element
}

interface DeleteAnomaliesByTypeParams {
  anomalyTypes: number[]
  inspectionId: string
}

export const useAnomalyDeletionByType = ({
  onDeletionSuccess,
  onDeletionError,
}: UseAnomalyDeletionByTypeProps) => {
  const deleteAnomaliesByType = useCallback(
    async ({ anomalyTypes, inspectionId }: DeleteAnomaliesByTypeParams) => {
      try {
        const response = await deleteAnomalyByTypeService(anomalyTypes, inspectionId)
        if (response.success) {
          onDeletionSuccess()
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: response.message,
            errorMessage: response.errorDetails,
            errors: response.errors,
          })
        }
      } catch (error) {
        return onDeletionError?.()
      }
    },
    [onDeletionSuccess, onDeletionError],
  )

  return { deleteAnomaliesByType }
}
