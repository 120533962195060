/**
 * Interface que representa a estrutura dos dados de um arquivo PGW (World file)
 */
export interface PGWData {
  xPixelSize: number // Largura de um pixel em unidades do mapa (direção x)
  yRotation: number // Termo de rotação para a linha
  xRotation: number // Termo de rotação para a coluna
  yPixelSize: number // Altura de um pixel em unidades do mapa (direção y, geralmente negativo)
  xOrigin: number // Coordenada X do centro do pixel superior esquerdo
  yOrigin: number // Coordenada Y do centro do pixel superior esquerdo
}

/**
 * Analisa o conteúdo de um arquivo PGW (World) e retorna um objeto estruturado
 *
 * @param input - Pode ser uma string contendo os dados do PGW ou um objeto File
 * @returns Uma Promise que resolve para um objeto PGWData
 */
export async function parsePGW(input: string | File): Promise<PGWData> {
  let content: string

  // Trata o input como string ou File
  if (typeof input === 'string') {
    content = input
  } else {
    // Lê o conteúdo do arquivo como texto
    content = await readFileAsText(input)
  }

  // Divide o conteúdo por quebras de linha e filtra linhas vazias
  const lines = content
    .split(/\r?\n/)
    .map((line) => line.trim())
    .filter((line) => line.length > 0)

  // Valida se há exatamente 6 valores
  if (lines.length !== 6) {
    throw new Error(`Formato PGW inválido: Esperados 6 valores, encontrados ${lines.length}`)
  }

  // Analisa cada linha como um número de ponto flutuante
  try {
    const values = lines.map((line) => parseFloat(line))

    // Verifica se algum valor é NaN
    if (values.some(isNaN)) {
      throw new Error('Formato PGW inválido: Falha ao analisar valores numéricos')
    }

    // Retorna os dados do PGW estruturados
    return {
      xPixelSize: values[0],
      yRotation: values[1],
      xRotation: values[2],
      yPixelSize: values[3],
      xOrigin: values[4],
      yOrigin: values[5],
    }
  } catch (error: any) {
    throw new Error(`Falha ao analisar os dados do PGW: ${error.message}`)
  }
}

/**
 * Função auxiliar para ler um arquivo como texto
 *
 * @param file - Objeto File a ser lido
 * @returns Uma Promise que resolve para o conteúdo do arquivo como string
 */
function readFileAsText(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result as string)
    }

    reader.onerror = () => {
      reject(new Error('Falha ao ler o arquivo'))
    }

    reader.readAsText(file)
  })
}
