import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getAnomalyStatus } from '~/services/Anomaly'
import { IResponseAnomalyStatus } from '~/services/Anomaly/types'
import ToastNotification from '~/components/ToastNotification'

export const useAnomalyStatuses = () => {
  const { t } = useTranslation()
  const [anomalyStatuses, setAnomalyStatuses] = useState<IResponseAnomalyStatus[]>([])

  const loadAnomalyStatuses = async () => {
    try {
      const response = await getAnomalyStatus()
      if (response.success) {
        setAnomalyStatuses(response.data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os status, tente novamente mais tarde.'),
      })
    }
  }

  return { anomalyStatuses, loadAnomalyStatuses }
}
