import { useContext, useEffect, useState, useRef } from 'react'
import { AzureMapsContext, IAzureMapsContextProps } from 'react-azure-maps'
import { usePGWParser } from '~/hooks/usePGWParser'
import { loadImageDimensions } from '../../utils/loadImageDimensions'
import { calculateImageCoordinates } from '../../utils/calculateImageCoordinates'
import { validateQuadrilateral } from '../../utils/validateQuadrilateral'
import { ISite } from '~/services/Site/types'
import atlas from 'azure-maps-control'

export default function OrthomosaicImageSource({
  site,
  shouldShowOrthomosaic,
  setIsLoadingOrthomosaic,
}: Readonly<{
  site: ISite
  shouldShowOrthomosaic: boolean
  setIsLoadingOrthomosaic: (v: boolean) => void
}>) {
  const imgUrl = site.orthomosaicImage?.fileUrl
  const { mapRef } = useContext<IAzureMapsContextProps>(AzureMapsContext)
  const [imageCoordinates, setImageCoordinates] = useState<[number, number][]>([])
  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number } | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const imageLayerRef = useRef<atlas.layer.ImageLayer | null>(null)

  const { pgwData, handleUrlChange } = usePGWParser(site?.orthomosaicAux?.fileUrl)

  useEffect(() => {
    setIsLoadingOrthomosaic(isLoading)
  }, [isLoading])

  useEffect(() => {
    if (!imgUrl) return
    loadImageDimensions(imgUrl, setIsLoading, setImageDimensions)
  }, [imgUrl])

  useEffect(() => {
    if (!imageDimensions || !pgwData) return

    const corners = calculateImageCoordinates(imageDimensions, pgwData)
    const isValidQuad = validateQuadrilateral(corners as any)

    if (isValidQuad) {
      setImageCoordinates(corners as any)
    } else {
      console.error('As coordenadas não formam um quadrilátero válido')
    }
  }, [imageDimensions, pgwData])

  useEffect(() => {
    if (!site?.orthomosaicAux) return
    handleUrlChange(site?.orthomosaicAux?.fileUrl)
  }, [site])

  useEffect(() => {
    if (!mapRef || !imageDimensions || imageCoordinates.length !== 4 || !pgwData || !imgUrl) return

    if (imageLayerRef.current) {
      mapRef.layers.remove(imageLayerRef.current)
      imageLayerRef.current = null
    }

    if (shouldShowOrthomosaic) {
      const layer = new atlas.layer.ImageLayer({
        url: imgUrl,
        coordinates: imageCoordinates,
        visible: true,
      })
      mapRef.layers.add(layer, 'labels')
      imageLayerRef.current = layer
    }

    return () => {
      if (imageLayerRef.current && mapRef) {
        mapRef.layers.remove(imageLayerRef.current)
        imageLayerRef.current = null
      }
    }
  }, [mapRef, imageCoordinates, shouldShowOrthomosaic, imgUrl])

  return null
}
