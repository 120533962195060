import React, { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react'
import { IAzureMapOptions } from 'react-azure-maps'
import { defaultAzureMapOptions } from '~/constants/defaultAzureMapOptions'

interface ControlAzureMapOptionsContextProps {
  currentAzureMapOptions: IAzureMapOptions
  setCurrentAzureMapOptions: Dispatch<SetStateAction<IAzureMapOptions>>
}

const ControlAzureMapOptionsContext = createContext<ControlAzureMapOptionsContextProps | undefined>(
  undefined,
)

export const ControlAzureMapOptionsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentAzureMapOptions, setCurrentAzureMapOptions] =
    useState<IAzureMapOptions>(defaultAzureMapOptions)

  const contextValue = useMemo(
    () => ({ currentAzureMapOptions, setCurrentAzureMapOptions }),
    [currentAzureMapOptions],
  )

  return (
    <ControlAzureMapOptionsContext.Provider value={contextValue}>
      {children}
    </ControlAzureMapOptionsContext.Provider>
  )
}

export const useControlAzureMapOptions = (): ControlAzureMapOptionsContextProps => {
  const context = useContext(ControlAzureMapOptionsContext)
  if (!context) {
    throw new Error(
      ' useControlAzureMapOptions deve ser usado dentro de um ControlAzureMapOptionsProvider',
    )
  }
  return context
}
