import { useRef, useCallback } from 'react'

export default function useDebouncedCallback(callback: () => void, delay: number) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const debouncedCallback = useCallback(() => {
    if (timeoutRef.current) return

    timeoutRef.current = setTimeout(() => {
      callback()
      clearTimeout(timeoutRef.current as NodeJS.Timeout)
      timeoutRef.current = null
    }, delay)
  }, [callback, delay])

  return debouncedCallback
}
