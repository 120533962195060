export function formatLanguageCode(code: string): string {
  const languageMap: { [key: string]: string } = {
    BR: 'pt-BR',
    US: 'en-US',
    ES: 'es-ES',
  }

  const formattedCode = languageMap[code.toUpperCase()]

  return formattedCode || code
}
