import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SelectOptionWithId } from '~/pages/Dashboard/Reports/types'
import ToastNotification from '~/components/ToastNotification'
import { getAllStringsInInspectionWithAnomaly } from '~/services/Anomaly'

export const useAllStringsInInspectionWithAnomaly = () => {
  const { t } = useTranslation()
  const [allStringsInInspectionWithAnomalyOptions, setAllStringsInInspectionWithAnomalyOptions] =
    useState<SelectOptionWithId[]>([])

  const loadAllStringsInInspectionWithAnomaly = async (inspectionId: string) => {
    try {
      const allStringsInInspectionWithAnomaly = await getAllStringsInInspectionWithAnomaly(inspectionId)
      if (allStringsInInspectionWithAnomaly.success) {
        const options = allStringsInInspectionWithAnomaly.data.map((a) => ({
          label: a.name,
          value: a.id.toString(),
          id: a.id.toString(),
        }))
        setAllStringsInInspectionWithAnomalyOptions(options)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: allStringsInInspectionWithAnomaly.errorDetails,
          errors: allStringsInInspectionWithAnomaly.errors,
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
    }
  }

  return { allStringsInInspectionWithAnomalyOptions, loadAllStringsInInspectionWithAnomaly }
}
