export const selectStyles = {
  width: 'calc( 100vw - 87vw)',
  '& .MuiOutlinedInput-root': {
    height: 38,
    padding: 0,
  },
  '& .MuiInputLabel-root': {
    transform: 'translate(14px, 9px) scale(1)',
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(14px, -9px) scale(0.75)',
  },

  '& .Mui-disabled': {
    opacity: 0.75,
    color: '#545454',
    cursor: 'not-allowed',
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: '#ced4da',
    },
    '& .MuiSelect-select': {
      backgroundColor: '#f9fbfc',
    },
  },
}

export const menuProps = {
  PaperProps: {
    style: {
      maxHeight: '50vh',
      zIndex: 1000,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
}
