import { Delete, CloudUpload, Edit } from '@mui/icons-material'
import { Box, Typography, List, ListItem, IconButton, ListItemText, Button } from '@mui/material'
import { useState, useEffect, useCallback } from 'react'

export type OrthomosaicFilePair = {
  pngFile: File | null
  pgwFile: File | null
}

export interface UploadedOrthomosaicFilePair {
  png: {
    url: string
    name: string
  }
  pgw: {
    url: string
    name: string
  }
}

type OrthomosaicFileDropProps = {
  onFilesChange: (files: OrthomosaicFilePair | null) => void
  error?: string
  uploadedOrthomosaicFilePair?: UploadedOrthomosaicFilePair | null
  onDelete: () => void
}

const validatePgwContent = (content: string): boolean => {
  const lines = content.split('\n').filter((line) => line.trim() !== '')
  return lines.length >= 6 && lines.every((line) => !isNaN(parseFloat(line)))
}

export default function OrthomosaicFileDrop({
  onFilesChange,
  error,
  uploadedOrthomosaicFilePair,
  onDelete,
}: Readonly<OrthomosaicFileDropProps>) {
  const [filePair, setFilePair] = useState<OrthomosaicFilePair>({ pngFile: null, pgwFile: null })
  const [dragActive, setDragActive] = useState(false)
  const [validationError, setValidationError] = useState<string | null>(null)
  const [isEditing, setIsEditing] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState<UploadedOrthomosaicFilePair | null>(null)

  useEffect(() => {
    if (uploadedOrthomosaicFilePair) {
      setUploadedFiles(uploadedOrthomosaicFilePair)
      setIsEditing(false)
    }
  }, [uploadedOrthomosaicFilePair])

  const handleDrag = useCallback((e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(e.type === 'dragenter' || e.type === 'dragover')
  }, [])

  const handleFileValidation = useCallback((file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const reader = new FileReader()

      reader.onload = (e) => {
        try {
          const content = e.target?.result as string
          const isValid = validatePgwContent(content)
          setValidationError(isValid ? null : 'O arquivo PGW não contém um formato válido')
          resolve(isValid)
        } catch (err) {
          setValidationError('Erro ao ler o arquivo PGW')
          resolve(false)
        }
      }

      reader.onerror = () => {
        setValidationError('Erro ao ler o arquivo PGW')
        resolve(false)
      }

      reader.readAsText(file)
    })
  }, [])

  const updateFilePair = useCallback((newPair: Partial<OrthomosaicFilePair>) => {
    setFilePair((prev) => ({ ...prev, ...newPair }))
    setValidationError(null)
  }, [])

  const processFiles = useCallback(
    async (files: File[]) => {
      let newPngFile = filePair.pngFile
      let newPgwFile = filePair.pgwFile

      for (const file of files) {
        const nameParts = file.name.split('.')
        if (nameParts.length < 2) continue

        const ext = nameParts[nameParts.length - 1].toLowerCase()

        if (ext === 'png') {
          newPngFile = file
        } else if (ext === 'pgw') {
          const isValid = await handleFileValidation(file)
          if (isValid) {
            newPgwFile = file
          } else {
            return // Skip invalid PGW files
          }
        }
      }

      updateFilePair({
        pngFile: newPngFile,
        pgwFile: newPgwFile,
      })

      if (isEditing && newPngFile && newPgwFile) {
        setUploadedFiles(null)
        setIsEditing(false)
      }
    },
    [filePair, handleFileValidation, updateFilePair, isEditing],
  )

  const handleDrop = useCallback(
    async (e: React.DragEvent) => {
      e.preventDefault()
      e.stopPropagation()
      setDragActive(false)

      if (e.dataTransfer.files?.length) {
        await processFiles(Array.from(e.dataTransfer.files))
      }
    },
    [processFiles],
  )

  const handleChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files?.length) {
        await processFiles(Array.from(e.target.files))
        e.target.value = '' // Reset input to allow selecting same files again
      }
    },
    [processFiles],
  )

  const removeFile = useCallback(
    (type: 'png' | 'pgw') => {
      updateFilePair({ [`${type}File`]: null })
    },
    [updateFilePair],
  )

  const startEditing = useCallback(() => {
    setIsEditing(true)
    setFilePair({ pngFile: null, pgwFile: null })
  }, [])

  const cancelEditing = useCallback(() => {
    setIsEditing(false)
    setFilePair({ pngFile: null, pgwFile: null })
    setValidationError(null)
  }, [])

  useEffect(() => {
    const complete = filePair.pngFile || filePair.pgwFile
    onFilesChange(complete ? filePair : null)
  }, [filePair, onFilesChange])

  const getDropzoneText = () => {
    if (filePair.pngFile && filePair.pgwFile) return 'Par completo selecionado'
    if (filePair.pngFile) return 'Falta selecionar o arquivo PGW'
    if (filePair.pgwFile) return 'Falta selecionar o arquivo PNG'
    return 'Arraste e solte os arquivos aqui ou clique para selecionar'
  }

  const getDropzoneSubtext = () => {
    if (filePair.pngFile && filePair.pgwFile) return 'Você pode substituir os arquivos se necessário'
    if (filePair.pngFile || filePair.pgwFile) return 'Selecione o arquivo restante'
    return 'Selecione os arquivos PNG e PGW do Ortomosaico (pode ser um de cada vez ou ambos juntos)'
  }

  return (
    <Box sx={{ mt: 2, width: '100%' }}>
      <Typography variant="subtitle1" gutterBottom>
        Upload do Ortomosaico (PNG + PGW)
      </Typography>

      {(error || validationError) && (
        <Typography color="error" variant="body2" sx={{ mb: 1 }}>
          {error ?? validationError}
        </Typography>
      )}

      {(uploadedFiles || filePair.pngFile || filePair.pgwFile) && (
        <Box sx={{ mt: 2, width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
              width: '100%',
            }}
          >
            <Typography variant="subtitle1">
              {uploadedFiles && !isEditing ? 'Arquivos carregados:' : 'Arquivos selecionados:'}
            </Typography>

            {uploadedFiles && !isEditing && (
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button variant="outlined" startIcon={<Edit />} onClick={startEditing} sx={{ ml: 2 }}>
                  Editar
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<Delete />}
                  onClick={() => {
                    setUploadedFiles(null)
                    setFilePair({ pngFile: null, pgwFile: null })
                    onFilesChange(null)
                    onDelete()
                  }}
                  color="error"
                  sx={{ ml: 1 }}
                >
                  Excluir
                </Button>
              </Box>
            )}
            {isEditing && (
              <Button variant="outlined" onClick={cancelEditing} color="error" sx={{ ml: 2 }}>
                Cancelar
              </Button>
            )}
          </Box>

          <List dense sx={{ width: '100%' }}>
            {(filePair.pngFile || (uploadedFiles?.png && !isEditing)) && (
              <ListItem
                secondaryAction={
                  isEditing &&
                  filePair.pngFile && (
                    <IconButton edge="end" onClick={() => removeFile('png')}>
                      <Delete />
                    </IconButton>
                  )
                }
                sx={{
                  border: '1px solid #eee',
                  borderRadius: 1,
                  mb: 1,
                  width: '100%',
                }}
              >
                <ListItemText
                  primary={filePair.pngFile?.name ?? uploadedFiles?.png.name}
                  secondary="Arquivo PNG do Ortomosaico"
                />
              </ListItem>
            )}
            {(filePair.pgwFile || (uploadedFiles?.pgw && !isEditing)) && (
              <ListItem
                secondaryAction={
                  isEditing &&
                  filePair.pgwFile && (
                    <IconButton edge="end" onClick={() => removeFile('pgw')}>
                      <Delete />
                    </IconButton>
                  )
                }
                sx={{
                  border: '1px solid #eee',
                  borderRadius: 1,
                  width: '100%',
                }}
              >
                <ListItemText
                  primary={filePair.pgwFile?.name ?? uploadedFiles?.pgw.name}
                  secondary="Arquivo PGW do Ortomosaico"
                />
              </ListItem>
            )}
          </List>
        </Box>
      )}

      {(isEditing || !uploadedFiles) && (
        <Box
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          sx={{
            border: dragActive ? '2px dashed #1976d2' : '2px dashed #ccc',
            borderRadius: 1,
            p: 4,
            textAlign: 'center',
            backgroundColor: dragActive ? 'rgba(25, 118, 210, 0.04)' : 'transparent',
            cursor: 'pointer',
            position: 'relative',
            mt: 1,
            width: '100%',
          }}
        >
          <input
            type="file"
            id="orthomosaic-upload"
            accept=".png,.pgw"
            onChange={handleChange}
            multiple
            style={{ display: 'none' }}
          />
          <label htmlFor="orthomosaic-upload">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <CloudUpload fontSize="large" color={dragActive ? 'primary' : 'action'} />
              <Typography variant="body1" sx={{ mt: 1 }}>
                {getDropzoneText()}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                {getDropzoneSubtext()}
              </Typography>
            </Box>
          </label>
        </Box>
      )}
    </Box>
  )
}
