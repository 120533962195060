import { useState, useEffect } from 'react'
import { parsePGW, PGWData } from '~/utils/ParsePGW'

/**
 * Hook personalizado para analisar arquivos PGW a partir de URLs, arquivos ou strings.
 *
 * @param value - Valor inicial (pode ser uma URL, um arquivo File ou uma string).
 * @returns Um objeto contendo:
 * - pgwData: Os dados analisados do arquivo PGW (ou null se não houver dados).
 * - error: Mensagem de erro (ou null se não houver erro).
 * - isLoading: Estado de carregamento (true enquanto o arquivo ou string está sendo processado).
 * - handleUrlChange: Função para processar um arquivo a partir de uma URL.
 * - handleFileChange: Função para ser usada em um input de arquivo.
 * - parseFromString: Função para analisar diretamente uma string.
 */
export function usePGWParser(value?: string | File) {
  const [pgwData, setPgwData] = useState<PGWData | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // Efeito para processar o valor inicial quando o hook é montado
  useEffect(() => {
    if (value) {
      if (typeof value === 'string') {
        // Assume it's a URL if it starts with http or is a valid URL
        if (value.startsWith('http://') || value.startsWith('https://')) {
          handleUrlChange(value)
        } else {
          parseFromString(value)
        }
      } else {
        handleFileChange(value)
      }
    }
  }, [value])

  /**
   * Função para processar um arquivo PGW a partir de uma URL.
   *
   * @param url - URL do arquivo no Blob Storage.
   */
  const handleUrlChange = async (url: string | null) => {
    if (!url) {
      setPgwData(null)
      setError(null)
      return
    }

    setIsLoading(true)
    setError(null)

    try {
      // Fetch the file from the URL
      const response = await fetch(url)
      if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.status} ${response.statusText}`)
      }

      // Get the blob and create a File-like object
      const blob = await response.blob()
      const fileName = url.split('/').pop() ?? 'file.pgw'
      const file = new File([blob], fileName, { type: blob.type })

      // Parse the file
      const data = await parsePGW(file)
      setPgwData(data)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Erro ao buscar ou processar o arquivo PGW')
      setPgwData(null)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Função para processar um arquivo PGW.
   *
   * @param file - Arquivo PGW selecionado.
   */
  const handleFileChange = async (file: File | null) => {
    if (!file) {
      setPgwData(null)
      setError(null)
      return
    }

    setIsLoading(true)
    setError(null)

    try {
      const data = await parsePGW(file)
      setPgwData(data)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Erro ao processar o arquivo PGW')
      setPgwData(null)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Função para analisar diretamente uma string.
   *
   * @param content - String contendo os dados do PGW.
   */
  const parseFromString = async (content: string) => {
    if (!content) {
      setPgwData(null)
      setError(null)
      return
    }

    setIsLoading(true)
    setError(null)

    try {
      const data = await parsePGW(content)
      setPgwData(data)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Erro ao processar a string PGW')
      setPgwData(null)
    } finally {
      setIsLoading(false)
    }
  }

  return { pgwData, error, isLoading, handleUrlChange, handleFileChange, parseFromString }
}
