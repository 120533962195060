import atlas from 'azure-maps-control'
import { useContext } from 'react'
import {
  AzureMapDataSourceProvider,
  AzureMapLayerProvider,
  AzureMapsContext,
  IAzureMapsContextProps,
} from 'react-azure-maps'

export default function ModulesDataSource({
  data,
}: Readonly<{ data: null | atlas.data.FeatureCollection }>) {
  const { mapRef } = useContext<IAzureMapsContextProps>(AzureMapsContext)

  if (!data || !mapRef) return null

  const camera = mapRef.getCamera()
  const minZoom = 20.5

  if ((camera.zoom ?? 0) < minZoom) return null

  return (
    <AzureMapDataSourceProvider id="modules" collection={data}>
      <AzureMapLayerProvider
        id="modules-labels"
        options={{
          iconOptions: {
            image: '',
            rotationAlignment: 'viewport',
          },
          textOptions: {
            textField: ['get', 'moduleNumber'],
            textFont: ['Open Sans SemiBold'],
            textAnchor: 'center',
            textJustify: 'center',
            textAllowOverlap: true,
            ignorePlacement: true,

            rotationAlignment: 'viewport',
            offset: [0, 0.4],
            color: '#ffffff',
          },
          minZoom,
        }}
        type="SymbolLayer"
      />
    </AzureMapDataSourceProvider>
  )
}
