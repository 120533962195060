import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import ToastNotification from '~/components/ToastNotification'
import BaseLayout from '~/Layouts/BaseLayout'
import { SelectOption } from '~/pages/Dashboard/Reports/types'
import { getAnomalyDetailsService, getAnomalySimple } from '~/services/Anomaly'
import { AnomalySimpleDTO, IAnomalyLIstProps, IRequestAnomaly } from '~/services/Anomaly/types'
import ErrorToast from '~/utils/toastErrorCatch'
import Modal from '../../../Modal'
import FiltersModal from '../filters'
import { NewSidebarOneAnomaly } from '../NewSideBarOneAnomaly'
import AnomaliesDetails from '../../Detalhes'
import * as S from '../../styles'
import MapHeader from '../MapHeader/MapHeader'
import { useSites } from '../../hooks/useSites'
import { useInspections } from '../../hooks/useInspections'
import { useAnomalyStatuses } from '../../hooks/useAnomalyStatuses'
import { useAnomaliesData } from '../../hooks/useAnomaliesData'
import { AzureMapsContext, IAzureMapsContextProps, IAzureMapLayerEvent } from 'react-azure-maps'
import AzureMapAnomalyPopup from '../AzureMapAnomalyPopup/AzureMapAnomalyPopup'
import ListaToggle from '../ListItens'
import { Box, IconButton, Modal as MUIModal, Typography, CircularProgress } from '@mui/material'
import { useAnomalyLocationChange } from '../../hooks/useAnomalyLocationChange'
import { useAnomalyDeletion } from '../../hooks/useAnomalyDeletion'
import { useAnomalyDeletionByType } from '../../hooks/useAnomalyDeletionByType'
import { useAnomalyMapEdit } from '../../hooks/useAnomalyMapEdit'
import ParkDataSource from '../ParkDataSource/ParkDataSource'
import SubparksDataSource from '../SubparksDataSource/SubparksDataSource'
import StringsDataSource from '../StringsDataSource/StringsDataSource'
import ModulesDataSource from '../ModulesDataSource/ModulesDataSource'
import AnomaliesStringsDataSource from '../AnomaliesStringsDataSource/AnomaliesStringsDataSource'
import AnomaliesModulesDataSource from '../AnomaliesModulesDataSource/AnomaliesModulesDataSource'
import AnomaliesOrphansDataSource from '../AnomaliesOrphansDataSource/AnomaliesOrphansDataSource'
import { filterVisibleFeatures } from '../../utils/filterVisibleFeatures'

import useDebouncedCallback from '~/hooks/useDebouncedCallback'
import CustomAzureMapMarker from '~/components/CustomAzureMapMarker/CustomAzureMapMarker'
import DefaultAzureMapContainer from '~/components/DefaultAzureMapContainer/DefaultAzureMapContainer'
import { closeButtonStyle, modalStyle } from './modalStyle'
import CloseIcon from '@mui/icons-material/Close'
import { useParkStaticGeoJSONs } from '../../hooks/useParkStaticGeoJSONs'
import ModulesPolygonDataSource from '../ModulesPolygonDataSource/ModulesPolygonDataSource'

import OrthomosaicImageSource from '../OrthomosaicImageSource/OrthomosaicImageSource'
import { useFetchSite } from '~/hooks/useFetchSite'

export default function MainAzureMap() {
  const { t } = useTranslation()
  const { state } = useLocation()

  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false)
  const [showSidebar, setShowSidebar] = useState<boolean>(false)
  const [isEditingCoordinates, setIsEditingCoordinates] = useState<boolean>(false)
  const [fetchDetailsData, setFetchDetailsData] = useState<boolean>(false)
  const [hoveredAnomaly, setHoveredAnomaly] = useState<any>(null)
  const [editingAnomaly, setEditingAnomaly] = useState<AnomalySimpleDTO | null>(null)
  const [editingAnomalyWillNotChange, setEditingAnomalyWillNotChange] = useState<boolean>(false)

  const [anomalyToDelete, setAnomalyToDelete] = useState<AnomalySimpleDTO | IAnomalyLIstProps | null>(
    null,
  )
  const [selectedSiteId, setSelectedSiteId] = useState<string>('')

  const { fetchSite, site } = useFetchSite(selectedSiteId)

  const [selectedInspectionId, setSelectedInspectionId] = useState<string>('')
  const [typesFilters, setTypesFilters] = useState<number[]>([])
  const [statusesFilters, setStatusesFilters] = useState<number[]>([])
  const [severitiesFilters, setSeveritiesFilters] = useState<number[]>([])
  const [allAnomaliesData, setAllAnomaliesData] = useState<any[]>([])
  const [currentAnomalyIndex, setCurrentAnomalyIndex] = useState<number>(0)
  const [anomaliesListInfo, setAnomaliesListInfo] = useState<boolean>(false)

  const { mapRef } = useContext<IAzureMapsContextProps>(AzureMapsContext)
  const {
    loadParkStaticGeoJSONsById,
    parkGeoJSON,
    subparksGeoJSON,
    stringsGeoJSON,
    modulesGeoJSON,
    handleSelectSubparks,
    lastSelectedSubparks,
    modulesPolygonGeoJSON,
    shouldDrawModulePolygons,
    setShouldDrawModulePolygons,
    isLoadingParkData,
  } = useParkStaticGeoJSONs()
  const [shouldShowOrthomosaic, setShouldShowOrthomosaic] = useState(false)
  const [isLoadingOrthomosaic, setIsLoadingOrthomosaic] = useState(false)

  const { siteOptions, loadSites } = useSites()
  const { inspectionOptions, loadInspection } = useInspections()
  const { anomalyStatuses, loadAnomalyStatuses } = useAnomalyStatuses()

  const [visibleStringsData, setVisibleStringsData] = useState(stringsGeoJSON)
  const [visibleModulesData, setVisibleModulesData] = useState(modulesGeoJSON)
  const [visibleModulesPolygonData, setVisibleModulesPolygonData] = useState(modulesGeoJSON)

  const {
    anomaliesStringsData,
    anomaliesModulesData,
    anomaliesOrphansData,
    anomaliesCountByType,
    loadAnomalies,
  } = useAnomaliesData(lastSelectedSubparks ?? '*')

  const onChangeLocationSuccess = useCallback(() => {
    generateNecessaryGeoJSONsAfterChanges(
      selectedInspectionId,
      true,
      typesFilters,
      statusesFilters,
      severitiesFilters,
    )
  }, [typesFilters, statusesFilters, severitiesFilters, selectedInspectionId])

  const onDeletionByTypeSuccess = useCallback(() => {
    generateNecessaryGeoJSONsAfterChanges(
      selectedInspectionId,
      true,
      typesFilters,
      statusesFilters,
      severitiesFilters,
    )
    setFetchDetailsData(true)
  }, [typesFilters, statusesFilters, severitiesFilters, selectedInspectionId])

  const { deleteAnomaliesByType } = useAnomalyDeletionByType({
    onDeletionSuccess: onDeletionByTypeSuccess,
  })

  const showAnomalyDetails = async (id: string, shouldMove = false) => {
    setEditingAnomalyWillNotChange(false)
    try {
      const response = await getAnomalyDetailsService(id)
      if (response.success) {
        setShowSidebar(true)
        setEditingAnomaly(response.data)
        setEditingAnomalyWillNotChange(true)

        if (!shouldMove || !mapRef) return

        const currentZoom = mapRef.getCamera().zoom ?? 1
        mapRef.setCamera({
          center: [
            response.data.manualLongitude ?? response.data.longitude,
            response.data.manualLatitude ?? response.data.latitude,
          ],
          zoom: currentZoom,
          type: 'fly',
        })
      }
    } catch (error) {
      console.error('Error Updating Anomaly Details: ' + error)
    }
  }

  const { changeAnomalyLocation } = useAnomalyLocationChange({
    onSuccess: onChangeLocationSuccess,
    showAnomalyDetails: (id) => {
      showAnomalyDetails(id)
    },
  })

  const onDeletionSuccess = useCallback(() => {
    setAnomalyToDelete(null)
    generateNecessaryGeoJSONsAfterChanges(
      selectedInspectionId,
      true,
      typesFilters,
      statusesFilters,
      severitiesFilters,
    )
    setFetchDetailsData(true)
    setShowSidebar(false)
  }, [typesFilters, statusesFilters, severitiesFilters, selectedInspectionId])

  const { deleteAnomaly } = useAnomalyDeletion({
    onDeletionSuccess,
  })

  const onEditingComplete = useCallback(() => {
    setIsEditingCoordinates(false)
  }, [])

  const { handleAnomalyMapEdit } = useAnomalyMapEdit({
    isEditingCoordinates,
    editingAnomaly,
    showAnomalyDetails: (e) => {
      showAnomalyDetails(e)
    },
    changeAnomalyLocation: (e) => {
      changeAnomalyLocation(e)
    },
    onEditingComplete,
  })

  const handleFilterAnomalies = (values: {
    types: number[]
    statuses: number[]
    severities: number[]
  }) => {
    setTypesFilters(values.types)
    setStatusesFilters(values.statuses)
    setSeveritiesFilters(values.severities)
    generateNecessaryGeoJSONsAfterChanges(
      selectedInspectionId,
      true,
      values.types,
      values.statuses,
      values.severities,
    )
    ToastNotification({
      id: 'success',
      type: 'success',
      message: t('Filtros Aplicados'),
      errorMessage: undefined,
      errors: undefined,
    })
    setShowFilterModal(false)
    setShowSidebar(false)
  }

  const generateNecessaryGeoJSONsAfterChanges: any = async (
    selectedInspection: string = '',
    onlyAnomalies: boolean = false,
    anomalyTypes: number[] = [],
    anomalyStatuses: number[] = [],
    anomalySeverities: number[] = [],
  ) => {
    const requestParams: IRequestAnomaly = {
      InspectionId: selectedInspection,
      V2: true,
      ...(anomalyTypes.length > 0 && { AnomalyTypes: anomalyTypes }),
      ...(anomalyStatuses.length > 0 && { Statuses: anomalyStatuses }),
      ...(anomalySeverities.length > 0 && { Severities: anomalySeverities }),
    }

    try {
      const response = await getAnomalySimple(requestParams)

      if (response.success) {
        loadAnomalies(response.data)

        if (onlyAnomalies) {
          return
        }

        loadAnomalies(response.data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      console.error(error)
      return (
        <ErrorToast message={t('Não foi possível carregar os dados, tente novamente mais tarde.')} />
      )
    }
  }

  const handlePreviousAnomaly = () => {
    const newIndex = currentAnomalyIndex === 0 ? allAnomaliesData.length - 1 : currentAnomalyIndex - 1
    showAnomalyDetails(allAnomaliesData[newIndex].properties.anomalyId, true)
    setCurrentAnomalyIndex(newIndex)
  }

  const handleNextAnomaly = () => {
    const newIndex = currentAnomalyIndex === allAnomaliesData.length - 1 ? 0 : currentAnomalyIndex + 1
    showAnomalyDetails(allAnomaliesData[newIndex].properties.anomalyId, true)
    setCurrentAnomalyIndex(newIndex)
  }

  const handleAnomalyMapMouseEnter = (e: any) => {
    const hasAnomaly = e?.shapes?.find((a: any) => {
      if (!a.dataSource) return
      const dataSourceId = a.dataSource.id
      return dataSourceId === 'orphan-anomalies' || dataSourceId === 'anomalies'
    })

    if (!hasAnomaly) return

    setHoveredAnomaly(hasAnomaly.data)
  }

  useEffect(() => {
    loadAnomalyStatuses()
    loadSites()
  }, [])

  useEffect(() => {
    if (selectedSiteId) {
      loadInspection(selectedSiteId)
      loadParkStaticGeoJSONsById(selectedSiteId)
    }
  }, [selectedSiteId])

  useEffect(() => {
    if (selectedInspectionId) {
      generateNecessaryGeoJSONsAfterChanges(selectedInspectionId)
      setShowSidebar(false)
    }
  }, [selectedInspectionId])

  useEffect(() => {
    if (state) {
      setSelectedSiteId(state.siteId)
      setSelectedInspectionId(state.inspectionId)
    }
  }, [state])

  useEffect(() => {
    if (!(anomaliesModulesData ?? anomaliesOrphansData)) return
    let tempAllAnomalies: any = []

    if (Object.keys(anomaliesModulesData).length > 0 && anomaliesModulesData.features.length > 0) {
      tempAllAnomalies = anomaliesModulesData.features
    }
    if (Object.keys(anomaliesOrphansData).length > 0 && anomaliesOrphansData.features.length > 0) {
      tempAllAnomalies = tempAllAnomalies.concat(anomaliesOrphansData.features)
    }

    setAllAnomaliesData(
      tempAllAnomalies.sort((a: any, b: any) => a.properties.anomalyId - b.properties.anomalyId),
    )
  }, [anomaliesModulesData, anomaliesOrphansData])

  const handleStaticFeaturesDataUpdate = useCallback(() => {
    if (!mapRef) return

    const camera = mapRef.getCamera()
    const bounds = camera.bounds

    if (camera.zoom === undefined || bounds === undefined) return

    if (bounds && stringsGeoJSON && modulesGeoJSON) {
      setVisibleStringsData({
        ...stringsGeoJSON,
        features: filterVisibleFeatures(stringsGeoJSON.features, bounds as any),
      })

      setVisibleModulesData({
        ...modulesGeoJSON,
        features: filterVisibleFeatures(modulesGeoJSON.features, bounds as any),
      })
      modulesPolygonGeoJSON &&
        setVisibleModulesPolygonData({
          ...modulesGeoJSON,
          features: filterVisibleFeatures(modulesPolygonGeoJSON.features, bounds as any),
        })
    }
  }, [mapRef, stringsGeoJSON, modulesGeoJSON])

  const debouncedHandleStaticFeaturesDataUpdate = useDebouncedCallback(
    handleStaticFeaturesDataUpdate,
    300,
  )

  useEffect(() => {
    debouncedHandleStaticFeaturesDataUpdate()
  }, [stringsGeoJSON, modulesGeoJSON])

  useEffect(() => {
    if (mapRef) {
      mapRef.events.add('move', debouncedHandleStaticFeaturesDataUpdate)

      return () => {
        mapRef.events.remove('move', debouncedHandleStaticFeaturesDataUpdate)
      }
    }
  }, [mapRef, debouncedHandleStaticFeaturesDataUpdate])

  useEffect(() => {
    if (mapRef) {
      mapRef.events.add('click', handleAnomalyMapEdit)

      return () => {
        mapRef.events.remove('click', handleAnomalyMapEdit)
      }
    }
  }, [isEditingCoordinates, mapRef])

  useEffect(() => {
    const mousemove = () => {
      if (!mapRef) return
      mapRef.getCanvasContainer().style.cursor = ''
      setHoveredAnomaly(null)
    }
    if (mapRef) {
      mapRef.events.add('mousemove', mousemove)

      return () => {
        mapRef.events.remove('mousemove', mousemove)
      }
    }
  }, [isEditingCoordinates, mapRef])

  useEffect(() => {
    setCurrentAnomalyIndex(0)
    if (showSidebar) {
      setShowDetails(false)
    }
  }, [showSidebar])

  const layerEvents: Partial<IAzureMapLayerEvent> = {
    mousemove: (e) => {
      if (mapRef) {
        mapRef.getCanvasContainer().style.cursor = 'pointer'
      }
      handleAnomalyMapMouseEnter(e)
    },
  }

  useEffect(() => {
    if (!selectedSiteId) return
    fetchSite(selectedSiteId)
  }, [selectedSiteId])

  return (
    <BaseLayout
      title={showDetails ? t('Detalhes') : t('Mapa')}
      extraContent={
        <MapHeader
          showDetails={showDetails}
          setShowDetails={setShowDetails}
          showFilterModal={showFilterModal}
          setShowFilterModal={setShowFilterModal}
          selectedSiteId={selectedSiteId}
          setSelectedSiteId={setSelectedSiteId}
          selectedInspectionId={selectedInspectionId}
          setSelectedInspectionId={setSelectedInspectionId}
          siteOptions={siteOptions}
          inspectionOptions={inspectionOptions}
          setShowSidebar={setShowSidebar}
          onChangeSelectedSubparks={handleSelectSubparks}
          setShouldDrawModulePolygons={setShouldDrawModulePolygons}
          shouldDrawModulePolygons={shouldDrawModulePolygons}
          shouldShowOrthomosaic={shouldShowOrthomosaic}
          setShouldShowOrthomosaic={setShouldShowOrthomosaic}
          isLoadingOrthomosaic={isLoadingOrthomosaic}
          disableOrthomosaic={site ? !site.orthomosaicImage || !site.orthomosaicAux : false}
        />
      }
    >
      <S.FlexWrapper>
        <MUIModal
          open={showDetails}
          onClose={() => setShowDetails(false)}
          aria-labelledby="anomalies-details-modal"
          aria-describedby="modal-showing-anomalies-details"
        >
          <Box sx={modalStyle}>
            <Typography
              variant="h5"
              sx={{ flex: 1, textAlign: 'center', mt: 3, mb: 2, borderBottom: '1px solid #f6f3f3' }}
            >
              {t('Detalhes')}
            </Typography>
            <IconButton onClick={() => setShowDetails(false)} sx={closeButtonStyle} size="large">
              <CloseIcon fontSize="medium" />
            </IconButton>
            <Box sx={{ maxHeight: '80vh', overflowY: 'scroll' }}>
              <AnomaliesDetails
                handleFetchData={(value: boolean) => setFetchDetailsData(value)}
                fetchDetailsData={fetchDetailsData}
                selectedInspection={
                  inspectionOptions.find(
                    (inspection) => inspection.value === selectedInspectionId,
                  ) as SelectOption
                }
                removeAnomalies={(a: any) =>
                  deleteAnomaliesByType({ anomalyTypes: a, inspectionId: selectedInspectionId })
                }
                handleShowDetails={(id: string) => showAnomalyDetails(id)}
                deleteAnomaly={(anomaly) => setAnomalyToDelete(anomaly)}
                inspectionId={selectedInspectionId}
              />
            </Box>
          </Box>
        </MUIModal>

        <Box sx={{ height: '100%' }}>
          <DefaultAzureMapContainer>
            {isLoadingParkData && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '10px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  zIndex: 1100,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  padding: '5px 10px',
                  borderRadius: '8px',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                }}
              >
                <CircularProgress size={30} thickness={4} />
                <Typography sx={{ mt: 1, fontWeight: 'medium' }}>
                  {t('Carregando os dados')}...
                </Typography>
              </Box>
            )}

            {anomaliesCountByType.length > 0 && (
              <ListaToggle
                isListVisible={anomaliesListInfo}
                toggleList={() => setAnomaliesListInfo(!anomaliesListInfo)}
                listItems={anomaliesCountByType}
              />
            )}

            <ParkDataSource data={parkGeoJSON} />

            <SubparksDataSource data={subparksGeoJSON} />

            <ModulesDataSource data={visibleModulesData} />

            <StringsDataSource data={visibleStringsData} layerEvents={layerEvents} />

            <ModulesPolygonDataSource
              data={visibleModulesPolygonData}
              shouldDrawModulePolygons={shouldDrawModulePolygons}
            />

            <AnomaliesStringsDataSource data={anomaliesStringsData} layerEvents={layerEvents} />

            <AnomaliesModulesDataSource data={anomaliesModulesData} layerEvents={layerEvents} />

            <AnomaliesOrphansDataSource data={anomaliesOrphansData} layerEvents={layerEvents} />

            {site && shouldShowOrthomosaic && (
              <OrthomosaicImageSource
                shouldShowOrthomosaic={shouldShowOrthomosaic}
                setIsLoadingOrthomosaic={setIsLoadingOrthomosaic}
                site={site}
              />
            )}

            {hoveredAnomaly && (
              <AzureMapAnomalyPopup
                hoveredAnomaly={hoveredAnomaly}
                anomalyStatuses={anomalyStatuses as any}
              />
            )}
            {editingAnomalyWillNotChange && editingAnomaly && (
              <CustomAzureMapMarker
                longitude={editingAnomaly?.manualLongitude ?? editingAnomaly?.longitude}
                latitude={editingAnomaly?.manualLatitude ?? editingAnomaly?.latitude}
              />
            )}
          </DefaultAzureMapContainer>
        </Box>

        {showSidebar && (
          <NewSidebarOneAnomaly
            anomaly={editingAnomaly}
            handleClose={() => {
              setShowSidebar(false)
              setEditingAnomaly(null)
            }}
            onAnomalySaved={() => {
              generateNecessaryGeoJSONsAfterChanges(
                selectedInspectionId,
                true,
                typesFilters,
                statusesFilters,
                severitiesFilters,
              )
              editingAnomaly && showAnomalyDetails(editingAnomaly.id)
            }}
            deleteAnomaly={(anomaly: any) => setAnomalyToDelete(anomaly)}
            isEditingCoordinates={isEditingCoordinates}
            setIsEditingCoordinates={setIsEditingCoordinates}
            setReset={() =>
              editingAnomaly &&
              changeAnomalyLocation({
                anomalyId: editingAnomaly.id,
                latitude: editingAnomaly.latitude,
                longitude: editingAnomaly.longitude,
                shouldReset: true,
              })
            }
            handlePrevious={handlePreviousAnomaly}
            handleNext={handleNextAnomaly}
          />
        )}

        <FiltersModal
          inspectionId={selectedInspectionId}
          open={showFilterModal}
          onClose={() => setShowFilterModal(false)}
          onSave={handleFilterAnomalies}
        />

        <Modal
          onClose={() => setAnomalyToDelete(null)}
          open={!!anomalyToDelete}
          title={t('Remover Anomalia')}
          confirmButtonText={t('Remover') ?? ''}
          cancelButtonText={t('Cancelar') ?? ''}
          cancelButtonAction={() => setAnomalyToDelete(null)}
          confirmButtonAction={() => anomalyToDelete && deleteAnomaly(anomalyToDelete.id)}
        >
          {t('Você tem certeza que deseja excluir esta anomalia?')}
          <strong>{` #${anomalyToDelete?.code} ${anomalyToDelete?.name}`}</strong>
        </Modal>
      </S.FlexWrapper>
    </BaseLayout>
  )
}
