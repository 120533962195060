import { useContext, useEffect } from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import CameraAltIcon from '@mui/icons-material/CameraAlt'

import html2canvas from 'html2canvas'
import { useTranslation } from 'react-i18next'
import ToastNotification from '~/components/ToastNotification'
import { AzureMapsContext, IAzureMapsContextProps } from 'react-azure-maps'
import CustomAzureMapMarker from '~/components/CustomAzureMapMarker/CustomAzureMapMarker'
import DefaultAzureMapProvider from '~/components/DefaultAzureMapProvider/DefaultAzureMapProvider'
import DefaultAzureMapContainer from '~/components/DefaultAzureMapContainer/DefaultAzureMapContainer'

interface MapLocationImageViewerProps {
  markers: Array<{
    id: string
    latitude: number
    longitude: number
    color: string
  }>
}

const MarkersViewer = ({ markers }: { markers: MapLocationImageViewerProps['markers'] }) => {
  return (
    <>
      {markers.map((marker, index) => (
        <CustomAzureMapMarker
          key={marker.id + '-' + index}
          latitude={marker.latitude}
          longitude={marker.longitude}
          color={marker.color}
          tooltip={index.toString()}
        />
      ))}
    </>
  )
}

function MapLocationImageViewerContainer({ markers }: Readonly<MapLocationImageViewerProps>) {
  const { t } = useTranslation()
  const { mapRef } = useContext<IAzureMapsContextProps>(AzureMapsContext)

  const handleDownloadMapScreenshot = () => {
    if (!mapRef) {
      return alert('Mapa ainda nao foi carregado')
    }

    const el = mapRef.getCanvasContainer()
    html2canvas(el, {
      useCORS: true,
      logging: true,
    })
      .then((canvas) => {
        try {
          const dataURL = canvas.toDataURL('image/png')
          const link = document.createElement('a')
          link.href = dataURL
          link.download = 'map-screenshot.png'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          ToastNotification({
            id: 'success',
            type: 'success',
            message: t('Imagem capturada com sucesso!'),
          })
        } catch (error) {
          alert('Falha ao converter o canvas para URL:')
        }
      })
      .catch(() => {
        alert('Falha ao converter o canvas para URL:')
      })
  }

  useEffect(() => {
    const firstMarker = markers[0]

    if (!mapRef || !firstMarker) return

    mapRef.setCamera({
      center: [firstMarker.longitude, firstMarker.latitude],
      zoom: 16,
      type: 'fly',
    })
  }, [mapRef, markers])
  return (
    <div style={{ width: '100%', height: '750px', borderRadius: '5px', overflow: 'hidden' }}>
      {' '}
      <DefaultAzureMapContainer>
        <Box sx={{ position: 'absolute', bottom: 10, right: 10, zIndex: 999 }}>
          <Tooltip title={t('Capturar Imagem do Mapa')}>
            <IconButton
              aria-label="camera"
              component="span"
              onClick={handleDownloadMapScreenshot}
              sx={{
                backgroundColor: '#1565c0',
                '&:hover': {
                  backgroundColor: '#0C4A7D',
                },
              }}
            >
              <CameraAltIcon sx={{ color: 'white' }} />
            </IconButton>
          </Tooltip>
        </Box>
        <MarkersViewer markers={markers} />
      </DefaultAzureMapContainer>
    </div>
  )
}

export default function MapLocationImageViewer(props: Readonly<MapLocationImageViewerProps>) {
  return (
    <DefaultAzureMapProvider>
      <MapLocationImageViewerContainer {...props} />
    </DefaultAzureMapProvider>
  )
}
