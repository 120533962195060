// Função utilitária para carregar a imagem e obter suas dimensões
export const loadImageDimensions = (
  imgUrl: string,
  setIsLoading: (loading: boolean) => void,
  setImageDimensions: (dimensions: { width: number; height: number }) => void,
) => {
  if (!imgUrl) return

  setIsLoading(true)

  const img = new Image()
  img.onload = () => {
    setImageDimensions({
      width: img.width,
      height: img.height,
    })
    setIsLoading(false)
  }
  img.onerror = (r) => {
    console.error('Erro ao carregar a imagem:', imgUrl)
    setIsLoading(false)
  }
  img.src = imgUrl
}
