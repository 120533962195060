import { FeatureCollection } from './geojson-types'
import { getParkGeoJsonsUrlsBySiteId } from './get-park-geo-jsons-urls.service'
import { ParkGeoJSONData } from './types'

async function fetchFile(url: string, then: (value: FeatureCollection) => void) {
  return fetch(url).then(async (r) => {
    const o = await r.json()
    then(o as FeatureCollection)
  })
}

interface ParkGeoJsonsFiles {
  siteId: string
  onOneGeoJSONLoad: (parkGeoJSONData: ParkGeoJSONData) => void
  shouldDrawModulePolygons: boolean
}
export async function getParkGeoJSONs(
  parkGeoJsonsFiles: ParkGeoJsonsFiles,
  parkGeoJSONData?: ParkGeoJSONData | null,
): Promise<ParkGeoJSONData | null> {
  const data = parkGeoJSONData ?? (await getParkGeoJsonsUrlsBySiteId(parkGeoJsonsFiles.siteId))

  if (!data) return null

  const promises = [] as Promise<any>[]

  if (parkGeoJSONData) {
    if (parkGeoJsonsFiles.shouldDrawModulePolygons) {
      for (const o of data.subparks) {
        if (o.modulesPolygonGeoJSON) {
          break
        }
        promises.push(
          fetchFile(o.modulesPolygonGeoJsonUrl, (value) => {
            o.modulesPolygonGeoJSON = value
            parkGeoJsonsFiles.onOneGeoJSONLoad(data)
          }),
        )
      }
    }

    await Promise.all(promises)

    parkGeoJsonsFiles.onOneGeoJSONLoad(data)

    return data
  }

  promises.push(
    fetchFile(data.parkGeoJsonUrl, (value) => {
      data.parkGeoJSON = value
      parkGeoJsonsFiles.onOneGeoJSONLoad(data)
    }),
  )
  promises.push(
    fetchFile(data.subarksGeoJsonUrl, (value) => {
      data.subparksGeoJSON = value
      parkGeoJsonsFiles.onOneGeoJSONLoad(data)
    }),
  )

  data.subparks.forEach((o) => {
    promises.push(
      fetchFile(o.stringsGeoJsonUrl, (value) => {
        o.stringsGeoJSON = value
        parkGeoJsonsFiles.onOneGeoJSONLoad(data)
      }),
    )
    promises.push(
      fetchFile(o.modulesGeoJsonUrl, (value) => {
        o.modulesGeoJSON = value
        parkGeoJsonsFiles.onOneGeoJSONLoad(data)
      }),
    )

    if (parkGeoJsonsFiles.shouldDrawModulePolygons) {
      promises.push(
        fetchFile(o.modulesPolygonGeoJsonUrl, (value) => {
          o.modulesPolygonGeoJSON = value
          parkGeoJsonsFiles.onOneGeoJSONLoad(data)
        }),
      )
    }
  })

  await Promise.all(promises)

  return data
}
