declare global {
  interface Window {
    ENV: Record<string, string>
  }
}

class RuntimeEnvironment {
  public readonly ServiceURL: string
  public readonly ServerLogs: string
  public readonly REACT_APP_AZURE_MAPS_KEY: string

  constructor() {
    const runtimeEnv = window.ENV ?? {}

    this.ServiceURL = runtimeEnv.REACT_APP_SERVER_API ?? process.env.REACT_APP_SERVER_API ?? ''

    this.ServerLogs = runtimeEnv.REACT_APP_SERVER_LOGS ?? process.env.REACT_APP_SERVER_LOGS ?? ''
    this.REACT_APP_AZURE_MAPS_KEY =
      runtimeEnv.REACT_APP_AZURE_MAPS_KEY ?? process.env.REACT_APP_AZURE_MAPS_KEY ?? ''
  }
}

export default new RuntimeEnvironment()
