import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getSimpleSite } from '~/services/Site'
import { SelectOption } from '~/pages/Dashboard/Reports/types'
import ToastNotification from '~/components/ToastNotification'

export const useSites = () => {
  const { t } = useTranslation()
  const [siteOptions, setSiteOptions] = useState<SelectOption[]>([])

  const loadSites = async () => {
    try {
      const site = await getSimpleSite()
      if (site.success) {
        const options = site.data.map((site) => ({
          label: site.nameAndLocal,
          value: site.id,
        }))
        setSiteOptions(options)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os Sites, tente novamente mais tarde.'),
          errorMessage: site.errorDetails,
          errors: site.errors,
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar as empresa, tente novamente mais tarde.'),
      })
    }
  }

  return { siteOptions, loadSites }
}
