import { Feature } from '~/services/GeoJSONs/geojson-types'

// Converte um valor em pixels para graus de latitude/longitude com base no nível de zoom e latitude fornecidos
const pixelsToDegrees = (pixels: number, zoom: number, latitude: number): number => {
  // Circunferência da Terra em metros (WGS84)
  const EARTH_CIRCUMFERENCE_METERS = 40075016.686

  // Fator de conversão de graus para radianos
  const DEG_TO_RAD = Math.PI / 180

  // Distância aproximada de 1 grau de latitude em metros
  const METERS_PER_DEGREE = 111320 // Aproximadamente 111.320 km por grau

  // Calcula quantos metros correspondem a um pixel no nível de zoom atual
  const metersPerPixel =
    (EARTH_CIRCUMFERENCE_METERS * Math.abs(Math.cos(latitude * DEG_TO_RAD))) / Math.pow(2, zoom + 8)

  // Converte metros por pixel para graus por pixel
  const degreesPerPixel = metersPerPixel / METERS_PER_DEGREE

  return pixels * degreesPerPixel
}

export const filterCloseFeatures = (
  f: any,
  zoom: number,
  latitude: number,
  MIN_DISTANCE_PIXELS = 6,
): any => {
  const features = f as Feature[]

  // Converte essa distância mínima para graus
  const minDistanceDegrees = pixelsToDegrees(MIN_DISTANCE_PIXELS, zoom, latitude)

  const filteredFeatures: Feature[] = []

  // Lista para armazenar as coordenadas já usadas, evitando sobreposição
  const usedCoordinates: number[][] = []

  features.forEach((feature) => {
    const coordinates = feature.geometry.coordinates

    if (feature.geometry.type === 'Point') {
      const [longitude, latitude] = coordinates as number[]

      // Verifica se essa feature está próxima de alguma já adicionada
      const isTooClose = usedCoordinates.some(
        ([usedLongitude, usedLatitude]) =>
          Math.abs(longitude - usedLongitude) < minDistanceDegrees &&
          Math.abs(latitude - usedLatitude) < minDistanceDegrees,
      )

      if (!isTooClose) {
        // Adiciona a feature à lista filtrada e armazena suas coordenadas
        filteredFeatures.push(feature)
        usedCoordinates.push([longitude, latitude])
      }
    } else if (feature.geometry.type === 'Polygon') {
      // Para polígonos, usamos o primeiro vértice como referência para evitar sobreposição
      const [longitude, latitude] = (coordinates as any)[0][0]

      // Verifica se o polígono está próximo de alguma feature já adicionada
      const isTooClose = usedCoordinates.some(
        ([usedLongitude, usedLatitude]) =>
          Math.abs(longitude - usedLongitude) < minDistanceDegrees &&
          Math.abs(latitude - usedLatitude) < minDistanceDegrees,
      )

      if (!isTooClose) {
        // Adiciona o polígono à lista filtrada e armazena suas coordenadas
        filteredFeatures.push(feature)
        usedCoordinates.push([longitude, latitude])
      }
    }
  })

  return filteredFeatures
}
