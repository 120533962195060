import { Search } from '@mui/icons-material'
import { Autocomplete, Box, TextField } from '@mui/material'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ISitesMapProps } from '~/services/Dashboard/types'
import { validarLatitude } from '~/utils/Validator'
import * as S from '../../styles'
import { AzureMapsContext, IAzureMapsContextProps } from 'react-azure-maps'
import CustomAzureMapMarker from '~/components/CustomAzureMapMarker/CustomAzureMapMarker'
import DefaultAzureMapProvider from '~/components/DefaultAzureMapProvider/DefaultAzureMapProvider'
import DefaultAzureMapContainer from '~/components/DefaultAzureMapContainer/DefaultAzureMapContainer'

type DashboardMapContainerProps = {
  sitesMap: ISitesMapProps[]
}

const DashboardMapContainer = ({ sitesMap }: DashboardMapContainerProps) => {
  const { t } = useTranslation()

  const { mapRef: azureMapRef } = useContext<IAzureMapsContextProps>(AzureMapsContext)

  const navigate = useNavigate()

  const [selectedSite, setSelectedSite] = useState<ISitesMapProps | null>(null)
  const [initMapCamera, setInitMapCamera] = useState({
    center: [0, 0],
    zoom: 0,
    type: 'fly' as const,
  })

  useEffect(() => {
    if (initMapCamera.zoom > 0) return

    if (azureMapRef) {
      const c = azureMapRef.getCamera()
      setInitMapCamera({
        center: c.center as number[],
        zoom: c.zoom as number,
        type: 'fly',
      })
    }
  }, [azureMapRef])

  const handleChangeValue = (e: any, value: string | null) => {
    const newSelectedSite = sitesMap.filter((site) => site.name === value)[0]
    setSelectedSite(newSelectedSite)

    if (newSelectedSite) {
      azureMapRef?.setCamera({
        center: [newSelectedSite.longitude, newSelectedSite.latitude],
        zoom: 16,
        type: 'fly',
      })
    } else {
      azureMapRef?.setCamera(initMapCamera)
    }
  }

  const filteredMaps = useMemo(() => {
    return (
      sitesMap.filter(
        (anomaly) =>
          validarLatitude(anomaly.latitude.toString()) && validarLatitude(anomaly.longitude.toString()),
      ) ?? []
    )
  }, [sitesMap])

  return (
    <S.MapWrapper>
      <S.Header>
        <h4>{t('Mapa dos parques solares')}</h4>
        <S.Chip
          label={t('Reset location')}
          color="primary"
          onClick={() => {
            azureMapRef?.setCamera(initMapCamera)
            setSelectedSite(null)
          }}
        />
      </S.Header>
      <S.SiteInputWrapper>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          fullWidth
          size="small"
          value={selectedSite?.name ?? ''}
          onChange={handleChangeValue}
          options={filteredMaps?.map((site) => site.name) ?? []}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              placeholder={t('Procurar parque solar') ?? ''}
              value={selectedSite?.name ?? ''}
              InputProps={{
                ...params?.InputProps,
                startAdornment: <Search />,
              }}
            />
          )}
        />
      </S.SiteInputWrapper>

      <S.MapContent>
        <Box sx={{ width: '100% !important', height: 'calc(100% - 85px) !important' }}>
          <DefaultAzureMapContainer>
            {filteredMaps.map((anomaly) => (
              <CustomAzureMapMarker
                key={anomaly.id}
                latitude={validarLatitude(anomaly.latitude.toString()) ? anomaly.latitude : 0}
                longitude={validarLatitude(anomaly.longitude.toString()) ? anomaly.longitude : 0}
                iconImage="pin-red"
                tooltip={anomaly.name}
                onClick={() => navigate(`/sites/inspections/${anomaly.id}`)}
              />
            ))}
          </DefaultAzureMapContainer>
        </Box>
      </S.MapContent>
    </S.MapWrapper>
  )
}

export default function DashboardMap(props: Readonly<DashboardMapContainerProps>) {
  return (
    <DefaultAzureMapProvider>
      <DashboardMapContainer {...props} />
    </DefaultAzureMapProvider>
  )
}
