import atlas from 'azure-maps-control'
import { useContext } from 'react'
import {
  AzureMapDataSourceProvider,
  AzureMapLayerProvider,
  AzureMapsContext,
  IAzureMapsContextProps,
} from 'react-azure-maps'

export default function ModulesPolygonDataSource({
  data,
  shouldDrawModulePolygons,
}: Readonly<{
  data: null | atlas.data.FeatureCollection
  shouldDrawModulePolygons: boolean
}>) {
  const { mapRef } = useContext<IAzureMapsContextProps>(AzureMapsContext)

  if (!data || !mapRef) return null
  const minZoom = 20.5

  return (
    <AzureMapDataSourceProvider id="ModulesPolygon" collection={data}>
      <AzureMapLayerProvider
        id="ModulesPolygons"
        options={{
          fillColor: 'transparent',
          fillOpacity: 1,
          strokeColor: '#0078D7',
          strokeWidth: 2,
          strokeOpacity: 1,
          minZoom,
          visible: shouldDrawModulePolygons,
        }}
        type="LineLayer"
      />
    </AzureMapDataSourceProvider>
  )
}
