import { Feature, FeatureCollection } from '~/services/GeoJSONs/geojson-types'

export type SourceData = FeatureCollection | null

// Função auxiliar para verificar se um ponto está dentro dos limites
const isPointInBounds = (point: [number, number], bounds: [number, number, number, number]): boolean => {
  const [longitude, latitude] = point
  const [west, south, east, north] = bounds

  // Verifica se a latitude está dentro dos limites
  const isLatitudeValid = latitude >= south && latitude <= north

  // Verifica se a longitude está dentro dos limites
  let isLongitudeValid = longitude >= west && longitude <= east

  // Se os limites cruzam a Linha Internacional de Data (west > east), ajusta a verificação
  if (west > east) {
    isLongitudeValid = longitude >= west || longitude <= east
  }

  return isLatitudeValid && isLongitudeValid
}

// Função para verificar se dois segmentos de linha se intersectam
const doSegmentsIntersect = (
  a1: [number, number],
  a2: [number, number],
  b1: [number, number],
  b2: [number, number],
): boolean => {
  const ccw = (A: [number, number], B: [number, number], C: [number, number]): number => {
    return (B[0] - A[0]) * (C[1] - A[1]) - (B[1] - A[1]) * (C[0] - A[0])
  }

  const ccw1 = ccw(a1, a2, b1)
  const ccw2 = ccw(a1, a2, b2)
  const ccw3 = ccw(b1, b2, a1)
  const ccw4 = ccw(b1, b2, a2)

  // Verifica se os segmentos se intersectam
  if (
    ((ccw1 > 0 && ccw2 < 0) || (ccw1 < 0 && ccw2 > 0)) &&
    ((ccw3 > 0 && ccw4 < 0) || (ccw3 < 0 && ccw4 > 0))
  ) {
    return true
  }

  // Verifica casos especiais (colineares)
  if (ccw1 === 0 && isPointOnSegment(a1, b1, a2)) return true
  if (ccw2 === 0 && isPointOnSegment(a1, b2, a2)) return true
  if (ccw3 === 0 && isPointOnSegment(b1, a1, b2)) return true
  if (ccw4 === 0 && isPointOnSegment(b1, a2, b2)) return true

  return false
}

// Função para verificar se um ponto está em um segmento de linha
const isPointOnSegment = (p: [number, number], a: [number, number], b: [number, number]): boolean => {
  const crossProduct = (p[0] - a[0]) * (b[1] - a[1]) - (p[1] - a[1]) * (b[0] - a[0])
  if (Math.abs(crossProduct) > Number.EPSILON) return false

  const dotProduct = (p[0] - a[0]) * (b[0] - a[0]) + (p[1] - a[1]) * (b[1] - a[1])
  if (dotProduct < 0) return false

  const squaredLength = (b[0] - a[0]) * (b[0] - a[0]) + (b[1] - a[1]) * (b[1] - a[1])
  if (dotProduct > squaredLength) return false

  return true
}

// Função para verificar se um polígono intersecta um retângulo
const doesPolygonIntersectBounds = (
  polygon: number[][][],
  bounds: [number, number, number, number],
): boolean => {
  const boundsPolygon = createBoundsPolygon(bounds)

  if (isPolygonCompletelyInsideBounds(polygon, bounds)) {
    return true
  }

  if (isBoundsCompletelyInsidePolygon(boundsPolygon, polygon)) {
    return true
  }

  return doPolygonAndBoundsIntersect(polygon, boundsPolygon)
}

// Função para criar um polígono a partir dos bounds
const createBoundsPolygon = (bounds: [number, number, number, number]): number[][][] => {
  const [west, south, east, north] = bounds
  return [
    [
      [west, south],
      [east, south],
      [east, north],
      [west, north],
      [west, south], // Fecha o polígono
    ],
  ]
}

// Função para verificar se o polígono está completamente dentro dos bounds
const isPolygonCompletelyInsideBounds = (
  polygon: number[][][],
  bounds: [number, number, number, number],
): boolean => {
  return polygon[0].every(([longitude, latitude]) => isPointInBounds([longitude, latitude], bounds))
}

// Função para verificar se o bounds está completamente dentro do polígono
const isBoundsCompletelyInsidePolygon = (
  boundsPolygon: number[][][],
  polygon: number[][][],
): boolean => {
  return boundsPolygon[0].every(([longitude, latitude]) =>
    isPointInPolygon([longitude, latitude], polygon),
  )
}

// Função para verificar se o polígono e o bounds se intersectam
const doPolygonAndBoundsIntersect = (polygon: number[][][], boundsPolygon: number[][][]): boolean => {
  for (const ring of polygon) {
    for (let i = 0; i < ring.length - 1; i++) {
      const p1 = ring[i]
      const p2 = ring[i + 1]

      for (const boundsRing of boundsPolygon) {
        for (let j = 0; j < boundsRing.length - 1; j++) {
          const b1 = boundsRing[j]
          const b2 = boundsRing[j + 1]

          if (doSegmentsIntersect(p1 as any, p2 as any, b1 as any, b2 as any)) {
            return true
          }
        }
      }
    }
  }

  return false
}
// Função para verificar se um ponto está dentro de um polígono
const isPointInPolygon = (point: [number, number], polygon: number[][][]): boolean => {
  const [x, y] = point
  let inside = false

  for (const ring of polygon) {
    for (let i = 0, j = ring.length - 1; i < ring.length; j = i++) {
      const [xi, yi] = ring[i]
      const [xj, yj] = ring[j]

      const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi

      if (intersect) {
        inside = !inside
      }
    }
  }

  return inside
}

export const filterVisibleFeatures = (
  features: Feature[],
  bounds: [number, number, number, number], // BoundingBox do Azure Maps
): Feature[] => {
  return features.filter((feature) => {
    const coordinates = feature.geometry.coordinates

    if (!coordinates || !Array.isArray(coordinates)) {
      console.warn('Feature com coordenadas inválidas:', feature)
      return false
    }

    let isWithinBounds = false

    if (feature.geometry.type === 'Point') {
      const [longitude, latitude] = coordinates as number[]

      if (typeof longitude !== 'number' || typeof latitude !== 'number') {
        console.warn('Feature com longitude ou latitude inválida:', feature)
        return false
      }

      isWithinBounds = isPointInBounds([longitude, latitude], bounds)
    } else if (feature.geometry.type === 'Polygon') {
      const polygonCoordinates = coordinates as number[][][]

      // Verifica se as coordenadas do polígono são válidas
      const isValidCoord = (coord: any) => Array.isArray(coord) && coord.length === 2
      const isValidRing = (ring: any) => Array.isArray(ring) && ring.every(isValidCoord)
      const isValidPolygon = polygonCoordinates.every(isValidRing)

      if (!isValidPolygon) {
        console.warn('Feature com coordenadas de polígono inválidas:', feature)
        return false
      }

      // Verifica se o polígono intersecta o bounds
      isWithinBounds = doesPolygonIntersectBounds(polygonCoordinates, bounds)
    }

    return isWithinBounds
  })
}
