import React from 'react'
import { AzureMapPopup } from 'react-azure-maps'
import { PopupOptions } from 'azure-maps-control'

export const wrapperStyles = {
  map: {
    height: '500px',
  },
  wrapper: {
    padding: '15px',
    marginTop: '15px',
  },
  buttonContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: '10px',
    gridAutoColumns: 'max-content',
    padding: '10px 0',
  },
  buttons: {
    padding: '15px',
    flex: 1,
  },
  popupStyles: {
    padding: '20px',
    color: 'black',
  },
}
interface AnomalyPopupProps {
  hoveredAnomaly: {
    properties: {
      anomalyManualLongitude?: number
      anomalyLongitude: number
      anomalyManualLatitude?: number
      anomalyLatitude: number
      anomalyImage: string
      anomalyName: string
      anomalyCode: string
      anomalyStatus: string
    }
  }
  anomalyStatuses: Array<{ id: string; status: string }>
}

const AzureMapAnomalyPopup: React.FC<AnomalyPopupProps> = ({ hoveredAnomaly, anomalyStatuses }) => {
  const position = [
    hoveredAnomaly.properties.anomalyManualLongitude ?? hoveredAnomaly.properties.anomalyLongitude,
    hoveredAnomaly.properties.anomalyManualLatitude ?? hoveredAnomaly.properties.anomalyLatitude,
  ]

  const popupOptions: PopupOptions = {
    position: position,
    pixelOffset: [0, 0],
    closeButton: true,
    fillColor: '#ffffff',
    fillOpacity: 1,
    showPointer: true,
    autoClose: true,
    openOnAdd: true,
    placement: 'top',
    anchor: 'bottom',
  }

  const status =
    anomalyStatuses.find((status) => status.id === hoveredAnomaly.properties.anomalyStatus)?.status ??
    'Status Desconhecido'

  return (
    <AzureMapPopup
      isVisible={true}
      options={popupOptions}
      popupContent={
        <div style={{ padding: '15px 10px', textAlign: 'center' }}>
          <img
            src={hoveredAnomaly.properties.anomalyImage}
            alt={hoveredAnomaly.properties.anomalyName}
            style={{
              width: '100%',
              height: '120px',
              objectFit: 'cover',
              borderRadius: '4px',
            }}
          />
          <div style={{ marginTop: '8px' }}>
            <p
              style={{
                fontWeight: 'bold',
                margin: '8px 0',
                color: '#333333',
              }}
            >
              #{hoveredAnomaly.properties.anomalyCode} - {hoveredAnomaly.properties.anomalyName}
            </p>
            <p
              style={{
                margin: '4px 0',
                color: '#666666',
              }}
            >
              Status: {status}
            </p>
          </div>
        </div>
      }
    ></AzureMapPopup>
  )
}

export default AzureMapAnomalyPopup
