import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getSimpleSubparksSimpleBySiteId } from '~/services/SubparksSimple/subparks-simple.service'
import { SelectOption } from '~/pages/Dashboard/Reports/types'
import ToastNotification from '~/components/ToastNotification'

export const useSubparksSimple = () => {
  const { t } = useTranslation()
  const [subparksSimpleOptions, setSubparksSimpleOptions] = useState<SelectOption[]>([])

  const showErrorMessage = (error: any) => {
    if (error.message === 'Sítio não existe ou não possui Park vinculado') {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Parque Solar não existe ou não possui um Parque ArcGis vinculado'),
        errorMessage: error.errorDetails,
        errors: error.errors,
      })
    } else {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
    }
  }
  const loadSimpleSubparksSimpleBySiteId = async (id: string) => {
    try {
      const subparksSimple = await getSimpleSubparksSimpleBySiteId(id)
      if (subparksSimple.success) {
        const options = subparksSimple.data.map((subparksSimple) => ({
          label: subparksSimple.name,
          value: subparksSimple.id.toString(),
        }))
        setSubparksSimpleOptions(options)
      } else {
        showErrorMessage(subparksSimple)
      }
    } catch (error) {
      showErrorMessage(error)
    }
  }

  return { subparksSimpleOptions, loadSimpleSubparksSimpleBySiteId }
}
