import { useCallback } from 'react'
import { postManualLocal } from '~/services/Anomaly'
import { IRequestManualLocal } from '~/services/Anomaly/types'
import ToastNotification from '~/components/ToastNotification'
import { useTranslation } from 'react-i18next'

interface UseAnomalyLocationChangeProps {
  onSuccess: () => void
  showAnomalyDetails: (id: string) => void
}

interface ChangeLocationParams {
  anomalyId: string
  latitude: number
  longitude: number
  shouldReset: boolean
  stringId?: number
}

export const useAnomalyLocationChange = ({
  onSuccess,
  showAnomalyDetails,
}: UseAnomalyLocationChangeProps) => {
  const { t } = useTranslation()

  const changeAnomalyLocation = useCallback(
    async ({ anomalyId, latitude, longitude, shouldReset, stringId }: ChangeLocationParams) => {
      const params: IRequestManualLocal = {
        anomalyId,
        latitude,
        longitude,
        reset: shouldReset,
        stringId,
      }

      try {
        const response = await postManualLocal(params)
        if (response.success) {
          onSuccess()
          ToastNotification({
            id: 'success',
            type: 'success',
            message: t('Anomalia alterada com sucesso.'),
            errorMessage: response.errorDetails,
            errors: response.errors,
          })
          showAnomalyDetails(anomalyId)
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Não foi possível alterar a localização, tente novamente mais tarde.'),
            errorMessage: response.errorDetails,
            errors: response.errors,
          })
        }
      } catch (error) {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
        })
      }
    },
    [onSuccess, showAnomalyDetails],
  )

  return { changeAnomalyLocation }
}
