import PersonIcon from '@mui/icons-material/Person'
import { Tooltip } from '@mui/material'
import React from 'react'
import ReactFlagsSelect from 'react-flags-select'
import { useTranslation } from 'react-i18next'
import { useAuth } from '~/contexts/Auth'
import * as S from './styles'
import LogoSolar from '~/assets/images/logoSolarInspec.png'

type BaseLayoutProps = {
  children: React.ReactNode
  extraContent?: React.ReactNode
  title: string | React.ReactNode
}

const BaseLayout = ({ children, title, extraContent }: BaseLayoutProps) => {
  const { t } = useTranslation()
  const { changeLanguage, languageSelected, user } = useAuth()

  return (
    <div>
      <S.NavDash>
        <S.Container>
          <S.ContainerUserInformation>
            <S.ContainerReactFlagsSelect>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '20px',
                  alignItems: 'center',
                  alignContent: 'center',
                  flexGrow: '1',
                }}
              >
                <img src={LogoSolar} alt="Logo Solar" style={{ width: '200px', marginRight: '10px' }} />

                {typeof title === 'string' ? (
                  <S.ContainerNav>
                    <h2>{title}</h2>
                  </S.ContainerNav>
                ) : (
                  title
                )}
                <S.HeaderExtraContent>{extraContent}</S.HeaderExtraContent>
              </div>
              <div style={{ width: '300px', position: 'relative' }}>
                <div style={{ marginTop: 10, position: 'absolute', right: '2%' }}>
                  <S.ContainerReactFlagsSelect>
                    {' '}
                    <ReactFlagsSelect
                      placeholder="Linguagem"
                      customLabels={{
                        BR: t('Português') ?? '',
                        US: t('Inglês') ?? '',
                        ES: t('Espanhol') ?? '',
                      }}
                      countries={['BR', 'US', 'ES']}
                      className="flag-selected"
                      fullWidth={false}
                      selected={languageSelected ?? ''}
                      onSelect={(code) => changeLanguage(code)}
                    />
                    <S.UserContainer>
                      <PersonIcon fontSize="large" />
                      <S.UserInfo>
                        <h5>{user?.name}</h5>
                        <Tooltip
                          title={
                            <ul>
                              {user?.companies
                                ?.filter((item) => item.name !== user?.company)
                                .map((company) => (
                                  <li key={company.id}> - {company.name}</li>
                                ))}
                            </ul>
                          }
                        >
                          <p style={{ cursor: 'pointer' }}>
                            {user?.company}
                            <span style={{ fontWeight: 'bold' }}>
                              {user?.companies &&
                                user.companies.length > 1 &&
                                ` (+${user.companies.length - 1})`}
                            </span>
                          </p>
                        </Tooltip>
                      </S.UserInfo>
                    </S.UserContainer>
                  </S.ContainerReactFlagsSelect>
                </div>
              </div>
            </S.ContainerReactFlagsSelect>
          </S.ContainerUserInformation>
        </S.Container>
      </S.NavDash>
      {children}
    </div>
  )
}

export default BaseLayout
