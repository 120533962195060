import DefaultAzureMapProvider from '../DefaultAzureMapProvider/DefaultAzureMapProvider'
import MainAzureMap from './components/MainAzureMap/MainAzureMap'

export default function MainMap() {
  return (
    <DefaultAzureMapProvider>
      <MainAzureMap />
    </DefaultAzureMapProvider>
  )
}
