import { useCallback } from 'react'
import { deleteAnomalyService } from '~/services/Anomaly'
import ToastNotification from '~/components/ToastNotification'
import { useTranslation } from 'react-i18next'

interface UseAnomalyDeletionProps {
  onDeletionSuccess: () => void
  onDeletionError?: () => JSX.Element
}

export const useAnomalyDeletion = ({ onDeletionSuccess, onDeletionError }: UseAnomalyDeletionProps) => {
  const { t } = useTranslation()

  const deleteAnomaly = useCallback(
    async (anomalyId: string) => {
      try {
        const response = await deleteAnomalyService(anomalyId)
        if (response.success) {
          onDeletionSuccess()
          ToastNotification({
            id: 'success',
            type: 'success',
            message: t('Anomalia excluída com sucesso!'),
            errorMessage: response.errorDetails,
            errors: response.errors,
          })
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: response.message,
            errorMessage: response.errorDetails,
            errors: response.errors,
          })
        }
      } catch (error) {
        return onDeletionError?.()
      }
    },
    [onDeletionSuccess, onDeletionError],
  )

  return { deleteAnomaly }
}
