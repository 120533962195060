import { openDB, IDBPDatabase } from 'idb'
import { ParkGeoJSONData } from '~/services/GeoJSONs/types'

interface GeoJSONStorageEntry {
  key: string
  value: ParkGeoJSONData
}

// Interface para padronizar erros
interface StorageError {
  message: string
  cause?: unknown
}

class IndexedDBGeoJSONsManager {
  private readonly databaseName = 'geojson-storage'
  private readonly collectionName = 'features'
  private databaseConnection: IDBPDatabase | null = null

  /**
   * Cria um erro padronizado para o gerenciador
   */
  private createError(message: string, cause?: unknown): StorageError {
    return {
      message: message,
      cause: cause,
    }
  }

  /**
   * Inicializa a conexão com o banco de dados IndexedDB
   * @throws StorageError se a inicialização falhar
   */
  async initializeDatabaseConnection(): Promise<void> {
    try {
      this.databaseConnection = await openDB(this.databaseName, 1, {
        upgrade(database) {
          if (!database.objectStoreNames.contains('features')) {
            database.createObjectStore('features')
          }
        },
      })
    } catch (error: unknown) {
      throw this.createError('Falha ao inicializar conexão com banco de dados', error)
    }
  }

  /**
   * Armazena uma feature GeoJSON no banco de dados
   * @param entry Entrada contendo identificador e feature GeoJSON
   * @returns true se o armazenamento foi bem sucedido
   * @throws StorageError se ocorrer algum erro durante o armazenamento
   */
  async storeParkGeoJSONData(entry: GeoJSONStorageEntry): Promise<boolean> {
    if (!this.databaseConnection) {
      await this.initializeDatabaseConnection()
    }

    try {
      await this.databaseConnection!.put(this.collectionName, entry.value, entry.key)
      return true
    } catch (error: unknown) {
      if (this.isStorageSpaceError(error)) {
        await this.handleInsufficientStorageSpace(entry)
        return true
      }
      throw this.createError('Erro ao armazenar feature GeoJSON', error)
    }
  }

  /**
   * Verifica se um erro está relacionado a falta de espaço no armazenamento
   */
  private isStorageSpaceError(error: unknown): boolean {
    if (error instanceof Error) {
      const errorMessage = error.message.toLowerCase()
      return (
        errorMessage.includes('quota') ||
        errorMessage.includes('storage') ||
        errorMessage.includes('space')
      )
    }
    return false
  }

  /**
   * Gerencia situações de espaço insuficiente no armazenamento
   * @param newEntry Nova entrada que estava tentando ser armazenada
   */
  private async handleInsufficientStorageSpace(newEntry: GeoJSONStorageEntry): Promise<void> {
    try {
      const storedFeatures = await this.getAllParkGeoJSONDatas()
      const itemsToRemoveCount = Math.ceil(storedFeatures.length * 0.2)
      const keysToRemove = storedFeatures.slice(0, itemsToRemoveCount).map((item) => item.key)

      await this.deleteParkGeoJSONDatas(keysToRemove)
      await this.databaseConnection!.put(this.collectionName, newEntry.value, newEntry.key)
    } catch (error: unknown) {
      throw this.createError('Erro ao gerenciar espaço insuficiente', error)
    }
  }

  /**
   * Remove features GeoJSON do armazenamento
   * @param identifiers Array de identificadores para remover
   */
  async deleteParkGeoJSONDatas(identifiers: string[]): Promise<void> {
    if (!this.databaseConnection) {
      await this.initializeDatabaseConnection()
    }

    try {
      const transaction = this.databaseConnection!.transaction(this.collectionName, 'readwrite')
      await Promise.all(identifiers.map((id) => transaction.store.delete(id)))
      await transaction.done
    } catch (error: unknown) {
      throw this.createError('Erro ao remover features GeoJSON', error)
    }
  }

  /**
   * Recupera todas as features GeoJSON armazenadas
   * @returns Array de entradas armazenadas
   */
  async getAllParkGeoJSONDatas(): Promise<GeoJSONStorageEntry[]> {
    if (!this.databaseConnection) {
      await this.initializeDatabaseConnection()
    }

    try {
      const allIdentifiers = await this.databaseConnection!.getAllKeys(this.collectionName)
      const allFeatures = await this.databaseConnection!.getAll(this.collectionName)

      return allIdentifiers.map((key, index) => ({
        key: key as string,
        value: allFeatures[index] as ParkGeoJSONData,
      }))
    } catch (error: unknown) {
      throw this.createError('Erro ao recuperar features GeoJSON', error)
    }
  }

  /**
   * Recupera uma feature GeoJSON específica pelo identificador
   * @param identifier Identificador da feature
   * @returns Feature encontrada ou null se não existir
   */
  async getParkGeoJSONDataByKey(identifier: string): Promise<ParkGeoJSONData | null> {
    if (!this.databaseConnection) {
      await this.initializeDatabaseConnection()
    }

    try {
      return await this.databaseConnection!.get(this.collectionName, identifier)
    } catch (error: unknown) {
      throw this.createError('Erro ao recuperar feature GeoJSON por identificador', error)
    }
  }

  /**
   * Fecha a conexão com o banco de dados
   */
  async closeDatabaseConnection(): Promise<void> {
    if (this.databaseConnection) {
      this.databaseConnection.close()
      this.databaseConnection = null
    }
  }
}

export default new IndexedDBGeoJSONsManager()
