export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 1,
  overflow: 'auto',
  borderRadius: 1,
}

export const closeButtonStyle = {
  position: 'absolute',
  right: 8,
  top: 8,
  color: 'text.secondary',
  zIndex: 1,
  '&:hover': {
    color: 'text.primary',
    bgcolor: 'action.hover',
  },
}
