import atlas from 'azure-maps-control'
import { AzureMapDataSourceProvider, AzureMapLayerProvider, IAzureMapLayerEvent } from 'react-azure-maps'

export default function AnomaliesStringsDataSource({
  data,
  layerEvents,
}: Readonly<{
  data: null | atlas.data.FeatureCollection
  layerEvents: Partial<IAzureMapLayerEvent>
}>) {
  if (!data) return null

  return (
    <AzureMapDataSourceProvider id="string-anomalies" collection={data}>
      <AzureMapLayerProvider
        id="string-anomalies-polygons"
        options={{
          fillOpacity: 0.8,
          fillColor: '#FF0000',
          minZoom: 15,
          visible: true,
          strokeWidth: 1,
          strokeColor: '#FF0000',
        }}
        type="PolygonLayer"
        events={layerEvents}
      />
    </AzureMapDataSourceProvider>
  )
}
