import {
  Apartment as ApartmentIcon,
  Battery4Bar as Battery4BarIcon,
  BatteryStd as BatteryStdIcon,
  FlashOn as FlashOnIcon,
  SolarPower as SolarPowerIcon,
  Troubleshoot as TroubleshootIcon,
} from '@mui/icons-material'
import { Tooltip as MaterialTooltip } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ISummaryInspectionProps, ISummaryProps } from '~/services/Dashboard/types'
import * as S from '../../styles'

type SummaryComponentProps = {
  readonly summary: (Partial<ISummaryProps> & Partial<ISummaryInspectionProps>) | undefined
  readonly dataType: 'dashboard' | 'inspection'
}

function SummaryComponent({ summary, dataType }: SummaryComponentProps) {
  const { t } = useTranslation()

  const formatNumber = (number: number) => {
    return number.toLocaleString('pt-BR', {
      maximumFractionDigits: 2, // Limita o número de casas decimais a 2
      minimumFractionDigits: 2, // Garante que sempre haverá duas casas decimais
    })
  }

  // Usando a função formatNumber para formatar totalInspectedPower
  const formattedPower = formatNumber(summary?.totalInspectedPower ?? 0)
  const formattedSiteTotalPower = formatNumber(summary?.siteTotalPower ?? 0)
  const formattedInspectionTotalPower = formatNumber(summary?.inspectedPower ?? 0)

  const summaryData = useMemo(() => {
    if (summary) {
      if (dataType === 'dashboard') {
        return [
          {
            title: t('Inspeções'),
            value: summary?.totalInspections,
            icon: (
              <S.IconWrapper bgcolor="#a7aec7">
                <TroubleshootIcon style={{ fill: '#fff' }} />
              </S.IconWrapper>
            ),
          },
          {
            title: t('Parques solares'),
            value: summary?.totalSites,
            icon: (
              <S.IconWrapper bgcolor="#d3c69e">
                <ApartmentIcon style={{ fill: '#fff' }} />
              </S.IconWrapper>
            ),
          },
          {
            title: t('Potência DC'),
            value: `${formattedPower} MW`,
            icon: (
              <S.IconWrapper bgcolor="#a2d1bd">
                <FlashOnIcon style={{ fill: '#fff' }} />
              </S.IconWrapper>
            ),
          },
        ]
      } else if (dataType === 'inspection') {
        return [
          {
            title: t('Potência Total'),
            value: `${formattedSiteTotalPower} MW`,
            icon: (
              <S.IconWrapper bgcolor="#a7aec7">
                <BatteryStdIcon style={{ fill: '#fff' }} />
              </S.IconWrapper>
            ),
          },
          {
            title: t('Potência Inspecionada'),
            value: `${formattedInspectionTotalPower} MW`,
            icon: (
              <S.IconWrapper bgcolor="#a2d1bd">
                <Battery4BarIcon style={{ fill: '#fff' }} />
              </S.IconWrapper>
            ),
          },
          {
            title: t('Módulos Inspecionados'),
            value: summary?.inspectedNumberOfModules,
            icon: (
              <S.IconWrapper bgcolor="#d3c69e">
                <SolarPowerIcon style={{ fill: '#fff' }} />
              </S.IconWrapper>
            ),
          },
        ]
      }
    }
    return []
  }, [summary])
  return (
    <S.SummaryWrapper>
      {summaryData?.map((item) => (
        <S.SummaryOption key={item.value}>
          {item.icon}
          <S.OptionInfos>
            <MaterialTooltip title="Inspeções">
              <h4>{item.title}</h4>
            </MaterialTooltip>
            <p>{item.value}</p>
          </S.OptionInfos>
        </S.SummaryOption>
      ))}
    </S.SummaryWrapper>
  )
}

export default SummaryComponent
