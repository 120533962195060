import atlas from 'azure-maps-control'
import { AzureMapDataSourceProvider, AzureMapLayerProvider, IAzureMapLayerEvent } from 'react-azure-maps'

export default function AnomaliesModulesDataSource({
  data,
  layerEvents,
}: Readonly<{
  data: null | atlas.data.FeatureCollection
  layerEvents: Partial<IAzureMapLayerEvent>
}>) {
  if (!data) return null

  return (
    <AzureMapDataSourceProvider id="anomalies" collection={data}>
      <AzureMapLayerProvider
        id="anomalies-polygons"
        options={{
          fillColor: ['get', 'anomalyColor'],
          strokeWidth: 5,
          strokeColor: '#5eff00',
          fillOpacity: 1,
        }}
        type="PolygonLayer"
        events={layerEvents}
      />
    </AzureMapDataSourceProvider>
  )
}
