export interface IUsersList {
  id?: string
  name?: string
  status?: number
  password?: string
  accessLevel?: number
  branch?: string
  company?: string
  email?: string
}

export enum IReportType {
  Termico = 1,
  Executivo = 2,
  CSV = 3,
}

export interface IUserGet {
  id: string
  name: string
  status: number
  accessLevel: number
  branch: string
  company: string
  email: string
  companyId: string
  branchId: string
}

export class Registration {
  site = ''
  inspectionDate = ''
  anomalyName = ''
  sections = ''
}

export class RegistrationError {
  site = ''
  inspectionDate = ''
  anomalyName = ''
  sections = ''
}

export type SelectOption = { label: string; value: string }

export type SelectOptionWithId = { label: string; value: string; id: string }
