import { AzureMap, ControlOptions, IAzureMap } from 'react-azure-maps'
import { useControlAzureMapOptions } from '~/contexts/ControlAzureMapOptions/ControlAzureMapOptionsContext'
import 'azure-maps-control/dist/atlas.min.css'

export default function DefaultAzureMapContainer(props: Readonly<IAzureMap>) {
  const { currentAzureMapOptions } = useControlAzureMapOptions()

  return (
    <AzureMap
      options={currentAzureMapOptions}
      controls={[
        {
          controlName: 'ZoomControl',
          options: { position: 'top-right' } as ControlOptions,
        },
        {
          controlName: 'CompassControl',
          controlOptions: { rotationDegreesDelta: 10 },
          options: { position: 'top-right' } as ControlOptions,
        },
        {
          controlName: 'PitchControl',
          controlOptions: { pitchDegreesDelta: 10 },
          options: { position: 'top-right' } as ControlOptions,
        },

        {
          controlName: 'FullscreenControl',
          options: { position: 'top-right' } as ControlOptions,
        },
        {
          controlName: 'StyleControl',
          controlOptions: { mapStyles: 'all' },
          options: { position: 'top-right' } as ControlOptions,
        },
      ]}
      {...props}
    ></AzureMap>
  )
}
