// Função utilitária para calcular as coordenadas da imagem
export const calculateImageCoordinates = (
  imageDimensions: { width: number; height: number }, // Dimensões da imagem em pixels
  pgwData: any, // Dados do arquivo .pgw contendo informações georreferenciadas
) => {
  const { width, height } = imageDimensions

  // Fator de segurança para evitar pontos colineares (que ficariam na mesma linha)
  // Valores muito próximos de 1 podem causar problemas em algoritmos de detecção de polígonos
  const safetyFactor = 0.9993 // Pequeno fator de ajuste para prevenir pontos colineares

  // Offset vertical para ajuste fino da posição da imagem
  // Valor positivo move a imagem para cima (norte) em sistemas onde Y cresce para norte
  // Valor negativo move para baixo (sul)
  // Ajuste este valor conforme necessário (em unidades de coordenadas do sistema)
  const yOffset = 0.000001 // Pequeno ajuste vertical - modifique conforme necessidade

  // Ajustamos a origem Y aplicando o offset
  // SUBTRAIMOS o offset para mover a imagem para cima (assumindo Y positivo para norte)
  // Se seu sistema tem Y positivo para sul, troque para ADIÇÃO
  const adjustedYOrigin = pgwData.yOrigin - yOffset

  // Coordenada superior esquerda (xOrigin, adjustedYOrigin)
  const topLeft = [pgwData.xOrigin, adjustedYOrigin]

  // Coordenada superior direita:
  // x: xOrigin + (largura da imagem * tamanho do pixel em x) * fator de segurança
  // y: mantém o adjustedYOrigin (mesma linha)
  const topRight = [pgwData.xOrigin + width * pgwData.xPixelSize * safetyFactor, adjustedYOrigin]

  // Coordenada inferior direita:
  // x: mesma lógica do topRight
  // y: adjustedYOrigin + (altura da imagem * tamanho do pixel em y) * fator de segurança
  const bottomRight = [
    pgwData.xOrigin + width * pgwData.xPixelSize * safetyFactor,
    adjustedYOrigin + height * pgwData.yPixelSize * safetyFactor,
  ]

  // Coordenada inferior esquerda:
  // x: mantém o xOrigin (mesma coluna)
  // y: mesma lógica do bottomRight
  const bottomLeft = [pgwData.xOrigin, adjustedYOrigin + height * pgwData.yPixelSize * safetyFactor]

  // Retorna as coordenadas no sentido horário começando pelo topo esquerdo
  return [topLeft, topRight, bottomRight, bottomLeft]
}
