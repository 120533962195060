import { useEffect, useState } from 'react'
import { AzureMapsProvider } from 'react-azure-maps'
import { useTranslation } from 'react-i18next'
import { useControlAzureMapOptions } from '~/contexts/ControlAzureMapOptions/ControlAzureMapOptionsContext'
import { formatLanguageCode } from '~/utils/formatLanguageCode'

export default function DefaultAzureMapProvider(
  props: Readonly<Parameters<typeof AzureMapsProvider>[0]>,
) {
  const { currentAzureMapOptions, setCurrentAzureMapOptions } = useControlAzureMapOptions()
  const { i18n } = useTranslation()

  const [key, setKey] = useState(0)

  useEffect(() => {
    setCurrentAzureMapOptions((o) => {
      return { ...o, language: formatLanguageCode(i18n.language) }
    })
    setKey((p) => p + 1)
  }, [i18n])

  useEffect(() => {
    setKey((p) => p + 1)
  }, [currentAzureMapOptions])

  return <AzureMapsProvider {...props} key={key} />
}
