import { useContext, useEffect, useState } from 'react'
import { AzureMapsContext, IAzureMapsContextProps } from 'react-azure-maps'
import { SelectOption } from '~/pages/Dashboard/Reports/types'
import { Feature, FeatureCollection } from '~/services/GeoJSONs/geojson-types'
import { getParkGeoJSONs } from '~/services/GeoJSONs/get-park-geo-jsons-files.service'
import { ParkGeoJSONData } from '~/services/GeoJSONs/types'
import IndexedDBGeoJSONsManager from '~/utils/IndexedDBGeoJSONsManager'

export function useParkStaticGeoJSONs() {
  const { mapRef } = useContext<IAzureMapsContextProps>(AzureMapsContext)
  const [isLoadingParkData, setIsLoadingParkData] = useState(false)

  const [parkGeoJSONData, setParkGeoJSONData] = useState<ParkGeoJSONData | null>(null)
  const [parkGeoJSON, setParkGeoJSON] = useState<FeatureCollection | null>(null)
  const [subparksGeoJSON, setSubparksGeoJSON] = useState<FeatureCollection | null>(null)
  const [modulesGeoJSON, setModulesGeoJSON] = useState<FeatureCollection | null>(null)
  const [modulesPolygonGeoJSON, setModulesPolygonGeoJSON] = useState<FeatureCollection | null>(null)

  const [stringsGeoJSON, setStringsGeoJSON] = useState<FeatureCollection | null>(null)

  const [lastSelectedSubparks, setLastSelectedSubparks] = useState<
    SelectOption | SelectOption[] | null | '*'
  >(null)

  const [shouldDrawModulePolygons, setShouldDrawModulePolygons] = useState(true)
  const [lastSiteIdUsed, setLastSiteIdUsed] = useState('')

  async function loadParkStaticGeoJSONsById(siteId: string) {
    setIsLoadingParkData(true)
    setLastSiteIdUsed(siteId)

    const key = siteId

    const isNewSiteId = lastSiteIdUsed !== key

    let zoomDone = false

    const updateStates = (parkGeoJSONData: ParkGeoJSONData) => {
      if (parkGeoJSONData.parkGeoJSON) {
        const features = parkGeoJSONData.parkGeoJSON.features
        const firstFeature = features[0]

        setParkGeoJSON(parkGeoJSONData.parkGeoJSON)

        if (isNewSiteId && !zoomDone) {
          mapRef?.setCamera({
            center: [firstFeature.geometry.coordinates[0], firstFeature.geometry.coordinates[1]],
            zoom: 14,
            type: 'fly',
          })
          zoomDone = true
        }
      }

      if (!subparksGeoJSON && parkGeoJSONData.subparksGeoJSON) {
        setSubparksGeoJSON(parkGeoJSONData.subparksGeoJSON)
      }
    }

    let safedData = !isNewSiteId ? parkGeoJSONData : null

    safedData = safedData ?? (await IndexedDBGeoJSONsManager.getParkGeoJSONDataByKey(key))

    let currentParkGeoJSONData = safedData || (siteId !== lastSiteIdUsed ? null : parkGeoJSONData)

    const data = await getParkGeoJSONs(
      {
        siteId,
        onOneGeoJSONLoad(parkGeoJSONData) {
          updateStates(parkGeoJSONData)
        },
        shouldDrawModulePolygons,
      },
      currentParkGeoJSONData,
    )

    if (!data) return

    setParkGeoJSONData(data)

    setSubparksGeoJSON(data.subparksGeoJSON)

    IndexedDBGeoJSONsManager.storeParkGeoJSONData({ key, value: data })

    setIsLoadingParkData(false)
    defineStatesRelatedToSubparksInView(lastSelectedSubparks ?? '*')
  }

  function defineStatesRelatedToSubparksInView(subparks?: SelectOption | SelectOption[] | null | '*') {
    let modulesGeoJSONFeatures = [] as Feature<Record<string, any>>[]
    let modulesPolygonGeoJSONFeatures = [] as Feature<Record<string, any>>[]
    let stringsGeoJSONFeatures = [] as Feature<Record<string, any>>[]

    const list = (Array.isArray(subparks) ? subparks : [subparks]) as SelectOption[]

    if (!parkGeoJSONData || !subparks) return

    parkGeoJSONData.subparks.forEach((subpark) => {
      if (subparks !== '*' && !list.some((i) => i.value === subpark.id.toString())) return

      modulesGeoJSONFeatures = [...modulesGeoJSONFeatures, ...(subpark.modulesGeoJSON?.features ?? [])]

      modulesPolygonGeoJSONFeatures = [
        ...modulesPolygonGeoJSONFeatures,
        ...(subpark.modulesPolygonGeoJSON?.features ?? []),
      ]

      if (modulesPolygonGeoJSON) {
        setModulesPolygonGeoJSON({
          ...modulesPolygonGeoJSON,
          features: modulesPolygonGeoJSONFeatures,
        })
      } else {
        setModulesPolygonGeoJSON(
          subpark.modulesPolygonGeoJSON
            ? { ...subpark.modulesPolygonGeoJSON, features: modulesPolygonGeoJSONFeatures }
            : null,
        )
      }

      if (modulesGeoJSON) {
        setModulesGeoJSON({
          ...modulesGeoJSON,
          features: modulesGeoJSONFeatures,
        })
      } else {
        setModulesGeoJSON(
          subpark.modulesGeoJSON
            ? { ...subpark.modulesGeoJSON, features: modulesGeoJSONFeatures }
            : null,
        )
      }

      stringsGeoJSONFeatures = [...stringsGeoJSONFeatures, ...(subpark.stringsGeoJSON?.features ?? [])]

      if (stringsGeoJSON) {
        setStringsGeoJSON({
          ...stringsGeoJSON,
          features: stringsGeoJSONFeatures,
        })
      } else {
        setStringsGeoJSON(
          subpark.stringsGeoJSON
            ? { ...subpark.stringsGeoJSON, features: stringsGeoJSONFeatures }
            : null,
        )
      }
    })
  }

  function handleSelectSubparks(
    subparks: SelectOption | SelectOption[] | null,
    allSubparks: SelectOption[],
  ) {
    if (!parkGeoJSONData) return

    if (subparks && Array.isArray(subparks) && allSubparks.length === subparks.length) {
      setLastSelectedSubparks('*')
      defineStatesRelatedToSubparksInView('*')
      return
    }

    setLastSelectedSubparks(subparks)

    if (!subparks) {
      setModulesGeoJSON(null)
      setStringsGeoJSON(null)
      return
    }

    defineStatesRelatedToSubparksInView(subparks)
  }
  useEffect(() => {
    defineStatesRelatedToSubparksInView(lastSelectedSubparks ?? '*')
  }, [parkGeoJSONData])

  useEffect(() => {
    if (!lastSiteIdUsed) return

    shouldDrawModulePolygons && loadParkStaticGeoJSONsById(lastSiteIdUsed)
  }, [shouldDrawModulePolygons])
  return {
    loadParkStaticGeoJSONsById,
    parkGeoJSON,
    subparksGeoJSON,
    modulesGeoJSON,
    stringsGeoJSON,
    handleSelectSubparks,
    lastSelectedSubparks,
    modulesPolygonGeoJSON,
    shouldDrawModulePolygons,
    setShouldDrawModulePolygons,
    isLoadingParkData,
  }
}
