import { deleteJson, getJson, postJson, putJson } from '../RequestService'
import {
  Data,
  IAllStatusInspection,
  IInspection,
  IInspectionPatch,
  IRequestAnalyzeStorage,
  IRequestInspectionImagesExample,
  IRequestInspectionsSimple,
  IRequestInspectionsUpload,
  IRequestSubparkAreas,
  IRequestUpdateStatus,
  IResponseInspectionImagesExample,
  IResponseInspectionUpload,
  IResponseUploadUrls,
  InspectionData,
  RequestInspections,
  RequestInspectionsId,
} from './types'

const defaultRoot = 'inspection'

export const postInspections = async (data: InspectionData) => {
  let url = `${defaultRoot}`
  return await postJson<any, any>(url, data)
}

export const postInspectionsImages = async (data: FormData) => {
  let url = `${defaultRoot}/upload`
  return await postJson<FormData, boolean>(url, data)
}

export const getInspections = async (data: RequestInspections) => {
  let url = `${defaultRoot}`

  url += `?PageNumber=${data?.PageNumber}&PageSize=${data.PageSize}&`

  if (data.SiteId) {
    const encodedLocal = encodeURIComponent(data.SiteId)
    url += `SiteId=${encodedLocal}&`
  }

  if (data.FromDate) {
    const encodedLocal = encodeURIComponent(data.FromDate)
    url += `FromDate=${encodedLocal}&`
  }

  if (data.ToDate) {
    const encodedLocal = encodeURIComponent(data.ToDate)
    url += `ToDate=${encodedLocal}&`
  }

  if (data.Description) {
    const encodedLocal = encodeURIComponent(data.Description)
    url += `Description=${encodedLocal}&`
  }
  if (data.CompanyId) {
    const encodedLocal = encodeURIComponent(data.CompanyId)
    url += `CompanyId=${encodedLocal}&`
  }

  if (data.Status || data.Status === 0) {
    const encodedLocal = encodeURIComponent(data.Status)
    url += `Status=${encodedLocal}`
  }

  return await getJson<IInspection[]>(url)
}

export const getInspectionsAll = async (data: RequestInspectionsId) => {
  let url = `${defaultRoot}?`

  if (data.InspectionId) {
    const encodedLocal = encodeURIComponent(data.InspectionId)
    url += `InspectionId=${encodedLocal}`
  }

  return await getJson<IInspection[]>(url)
}

export const deleteInspection = async (id: string) => {
  let url = `${defaultRoot}?id=${id}`
  return await deleteJson<boolean, boolean>(url)
}

export const patchInspection = async (data: IInspectionPatch) => {
  let url = `${defaultRoot}`
  return await putJson<unknown, IInspectionPatch>(url, data)
}

export const getInspectionsSimple = async (data: IRequestInspectionsSimple) => {
  let url = `${defaultRoot}/simple?`

  if (data.SiteId) {
    const encodedLocal = encodeURIComponent(data.SiteId)
    url += `SiteId=${encodedLocal}`
  }
  return await getJson<Data>(url)
}

export const getAllStatus = async () => {
  let url = `${defaultRoot}/statuses`
  return await getJson<IAllStatusInspection[]>(url)
}

export const getSubparkAreas = async (data: IRequestSubparkAreas) => {
  let url = `${defaultRoot}/subparkareas`

  if (data.SubparkId) {
    const encodedLocal = encodeURIComponent(data.SubparkId)
    url += `?SubparkId=${encodedLocal}`
  }
  if (data.GetModules) {
    const encodedLocal = encodeURIComponent(data.GetModules)
    url += `&GetModules=${encodedLocal}`
  }

  return await getJson<any>(url)
}

export const postUpdateStatus = async (data: IRequestUpdateStatus) => {
  let url = `${defaultRoot}/updatestatus`
  return await postJson<unknown, unknown>(url, data)
}

export const getInspectionsUpload = async (data: IRequestInspectionsUpload) => {
  let url = `${defaultRoot}/upload?`

  if (data.InspectionId) {
    const encodedLocal = encodeURIComponent(data.InspectionId)
    url += `InspectionId=${encodedLocal}`
  }
  if (data.PairType) {
    const encodedType = encodeURIComponent(data.PairType)
    url += `&PairType=${encodedType}`
  }

  return await getJson<IResponseInspectionUpload>(url)
}

export const getInspectionsExampleImages = async (data: IRequestInspectionImagesExample) => {
  let url = `${defaultRoot}/viewuploads`

  url += `?PageNumber=${data?.PageNumber}&PageSize=${data.PageSize}&`

  if (data.InspectionId) {
    const encodedLocal = encodeURIComponent(data.InspectionId)
    url += `InspectionId=${encodedLocal}&`
  }
  return await getJson<IResponseInspectionImagesExample>(url)
}

export const getGenerateUploadUrls = async (data: RequestInspectionsId) => {
  let url = `${defaultRoot}/generateuploadurls`
  if (data.InspectionId) {
    const encodedLocal = encodeURIComponent(data.InspectionId)
    url += `?InspectionId=${encodedLocal}`
  }
  return await getJson<IResponseUploadUrls>(url)
}

export const postReprocessInspection = async (inspectionId: string) => {
  return await postJson<{ inspectionId: string }, number>(`${defaultRoot}/reprocess`, { inspectionId })
}

export const postAnalyzeStorageAsync = async (data: IRequestAnalyzeStorage) => {
  let url = `${defaultRoot}/analyzestorageasync`
  if (data.InspectionId) {
    const encodedLocal = encodeURIComponent(data.InspectionId)
    url += `?InspectionId=${encodedLocal}`
  }
  if (data.UploadPairType) {
    const encodedLocal = encodeURIComponent(data.UploadPairType)
    url += `&UploadPairType=${encodedLocal}`
  }
  return await postJson<unknown, unknown>(url, data)
}
