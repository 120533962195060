import { Box, Paper, TableCell } from '@mui/material'
import styled from 'styled-components'

export const DetailsWrapper = styled(Box)`
  padding: 24px 2%;
  display: flex;
  gap: 20px;
  flex-direction: column;
`

export const SearchWrapper = styled(Paper)`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

export const ContentWrapper = styled(Paper)`
  padding: 10px 20px;
  z-index: 1;
  overflow: none;
`

export const ImagetableCell = styled(TableCell)`
  svg {
    cursor: pointer;
  }

  position: relative;
`

export const ImageThumb = styled(Box)`
  position: absolute;
  top: -55px;
  left: 55px;
  z-index: 999;

  img {
    height: 100px;
    border-radius: 8px;
  }
`

export const Inputs = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  gap: 20px;
  align-items: center;
`

export const Buttons = styled(Box)`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 20px;
`

export const IconButton = styled(Box)`
  cursor: pointer;
`

export const ModalWrapper = styled(Box)`
  display: flex;
  height: 80vh;

  gap: 20px;

  img {
    height: 100%;
  }
`

export const AlignCenter = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const ModalContent = styled(Box)`
  display: block;
  min-width: 300px;
`

export const ModalItemContent = styled(Box)`
  padding-top: 20px;

  display: flex;
  align-items: center;

  h4 {
    padding-right: 4px;
  }
`
