import atlas from 'azure-maps-control'
import { AzureMapDataSourceProvider, AzureMapLayerProvider } from 'react-azure-maps'

export default function ParkDataSource({
  data,
}: Readonly<{ data: null | atlas.data.FeatureCollection }>) {
  if (!data) return null
  return (
    <AzureMapDataSourceProvider id="park" collection={data}>
      <AzureMapLayerProvider
        id="park-label"
        options={{
          iconOptions: {
            image: 'pin-blue',
            rotationAlignment: 'viewport',
          },
          textOptions: {
            textField: ['get', 'parkName'],
            textFont: ['Open Sans SemiBold'],
            textSize: 13,
            textAnchor: 'center',
            textJustify: 'center',
            color: '#ffffff',
            padding: 10,
            textAllowOverlap: true,
            ignorePlacement: true,
            rotationAlignment: 'viewport',
          },
          maxZoom: 13,
        }}
        type="SymbolLayer"
      />
    </AzureMapDataSourceProvider>
  )
}
