import React, { useMemo, useRef, useEffect } from 'react'
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@mui/lab'
import { Typography, Paper, Tooltip, Box, useTheme, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import EditIcon from '@mui/icons-material/Edit'
import moment from 'moment'

const StyledTimelineDot = styled(TimelineDot)(({ theme }) => ({
  width: 16,
  height: 16,
  backgroundColor: theme.palette.primary.main,
  boxShadow: '0 0 0 4px rgba(25, 118, 210, 0.16)',
  '&:hover': {
    transform: 'scale(1.2)',
    transition: 'transform 0.2s ease-in-out',
  },
}))

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: '12px 16px',
  borderRadius: '12px',
  boxShadow: theme.shadows[3],
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: theme.shadows[6],
    transform: 'translateY(-4px)',
  },
}))

export default function CustomTimeline({
  data,
  onEdit,
}: Readonly<{
  data: {
    date: string
    title: string
    subtitle: string
    description: string
  }[]
  onEdit?: () => void
}>) {
  const theme = useTheme()
  const timelineRef = useRef<HTMLDivElement>(null)
  const lineRef = useRef<HTMLDivElement>(null)

  // Ordena do mais recente para o mais antigo
  const sortedData = useMemo(() => {
    return [...data].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
  }, [data])

  useEffect(() => {
    if (timelineRef.current && lineRef.current) {
      lineRef.current.style.width = `${timelineRef.current.scrollWidth}px`
    }
  }, [sortedData])

  const calculateSpacing = useMemo(() => {
    const MILLISECONDS_IN_ONE_DAY = 1000 * 3600 * 24

    // Converter datas para timestamps e calcular diferenças em dias
    const timestamps = sortedData.map((item) => new Date(item.date).getTime())
    const daysDiffs = timestamps
      .map((t, i) => (i > 0 ? (timestamps[i - 1] - t) / MILLISECONDS_IN_ONE_DAY : 0))
      .slice(1)

    // Aplicar raiz quadrada para suavizar grandes diferenças
    const normalizedDiffs = daysDiffs.map((d) => Math.sqrt(d))

    // Calcular a soma total dos espaçamentos normalizados
    const totalNormalized = normalizedDiffs.reduce((acc, d) => acc + d, 0) || 1

    const PX_PER_DAY = totalNormalized <= 20 ? 5 : 0.04

    if (sortedData.length <= 1) return {}

    const spacings: Record<string, number> = {}
    const getItemId = (item: (typeof sortedData)[0], index: number) =>
      `${item.date}-${item.title}-${index}`.replace(/\s+/g, '_')

    spacings[getItemId(sortedData[0], 0)] = 0

    for (let i = 1; i < sortedData.length; i++) {
      const currentDate = new Date(sortedData[i].date)
      const prevDate = new Date(sortedData[i - 1].date)

      const daysDiff = Math.round(
        Math.abs(currentDate.getTime() - prevDate.getTime()) / MILLISECONDS_IN_ONE_DAY,
      )

      const spacing = daysDiff === 0 ? 0 : daysDiff * PX_PER_DAY

      spacings[getItemId(sortedData[i], i)] = spacing
    }

    return spacings
  }, [sortedData])

  const getItemId = (item: (typeof sortedData)[0], index: number) =>
    `${item.date}-${item.title}-${index}`.replace(/\s+/g, '_')

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: 2,
        position: 'relative',
        py: 2,
        px: 2,
      }}
    >
      {onEdit && (
        <Box sx={{ position: 'absolute', top: 8, right: 8, zIndex: 10 }}>
          <IconButton color="primary" onClick={onEdit} sx={{ borderRadius: 2 }}>
            <EditIcon />
            <Typography sx={{ ml: 0.5, mr: 4, fontSize: '16px' }} variant="body2" color="text.secondary">
              Editar timeline
            </Typography>
          </IconButton>
        </Box>
      )}

      <Box
        sx={{
          width: '100%',
          overflowX: 'auto',
          backgroundColor: 'white',
          borderRadius: 2,
          position: 'relative',
          py: 2,
          px: 2,
        }}
      >
        <div
          ref={lineRef}
          style={{
            height: '3px',
            backgroundColor: '#1976d2',
            position: 'absolute',
            bottom: '40px',
            left: 0,
            transition: 'width 0.3s ease',
          }}
        />

        <Box ref={timelineRef}>
          <Timeline
            sx={{
              flexDirection: 'row',
              display: 'inline-flex',
              alignItems: 'center',
              height: '170px',
              minWidth: '100%',
              padding: 0,
              margin: 0,
              '& .MuiTimelineItem-root': {
                '&:before': { display: 'none' },
                minWidth: 'auto',
              },
            }}
          >
            {sortedData.map((item, index) => (
              <TimelineItem
                key={getItemId(item, index)}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginLeft: `${calculateSpacing[getItemId(item, index)] || 0}px`,
                }}
              >
                <TimelineContent
                  sx={{
                    textAlign: 'center',
                    mt: 2,
                    width: '230px',
                  }}
                >
                  <Tooltip title={item.subtitle} arrow placement="top">
                    <StyledPaper
                      elevation={1}
                      sx={{
                        p: 0,
                        overflow: 'auto',
                        borderRadius: '5px',
                        height: '70px',
                      }}
                    >
                      <Typography variant="caption" color="text.secondary" sx={{ mb: 1 }}>
                        {moment(item.date).format('DD/MM/YYYY')}
                      </Typography>
                      <Typography variant="subtitle2" fontWeight="bold">
                        {item.title}
                      </Typography>
                    </StyledPaper>
                  </Tooltip>
                </TimelineContent>

                <TimelineSeparator
                  sx={{ flexDirection: 'column', height: '100%', transform: 'rotate(180deg)' }}
                >
                  <Tooltip title={item.description} arrow placement="bottom">
                    <StyledTimelineDot
                      variant="outlined"
                      color="primary"
                      sx={{ width: 11, height: 11 }}
                    />
                  </Tooltip>
                  <TimelineConnector
                    sx={{
                      width: '2px',
                      height: '20px',
                      backgroundColor: theme.palette.primary.light,
                    }}
                  />
                </TimelineSeparator>
              </TimelineItem>
            ))}
          </Timeline>
        </Box>
      </Box>
    </Box>
  )
}
