import { getJson } from '../RequestService'
import { GeoJSONsUrlPark, ParkGeoJSONData } from './types'

export async function getParkGeoJsonsUrlsBySiteId(siteId: string) {
  try {
    const response = await getJson<GeoJSONsUrlPark>(`park/geojsonurls?SiteId=${siteId}`)

    return response.data as ParkGeoJSONData
  } catch (error) {
    console.error('Erro ao buscar dados do parque solar:', error)
    throw error
  }
}
