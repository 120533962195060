import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CollectionsIcon from '@mui/icons-material/Collections'
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep'
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import InfoIcon from '@mui/icons-material/Info'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import LandscapeIcon from '@mui/icons-material/Landscape'
import MapIcon from '@mui/icons-material/Map'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import UploadIcon from '@mui/icons-material/Upload'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import axios from 'axios'
import ExifReader from 'exifreader'
import moment from 'moment'
import { ChangeEvent, forwardRef, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import uuid from 'react-uuid'
import { TableComponents, TableVirtuoso } from 'react-virtuoso'
import { LinearLoadingBackdrop } from '~/components/Loading/Loading'
import ModalCustom from '~/components/ModalCustom'
import ToastNotification from '~/components/ToastNotification'
import {
  getGenerateUploadUrls,
  getInspectionsUpload,
  postAnalyzeStorageAsync,
} from '~/services/Inspections'
import { EUploadPairType, IInspectionUploadResponse } from '~/services/Inspections/types'
import { getInspectionsAll, postFile } from '~/services/InspectionsFile'
import { InspectionFileDto } from '~/services/InspectionsFile/types'
import MapLocationImageViewer from './MapLocationImageViewer'

enum ItemStatus {
  Uploaded = 'Enviado',
  Uploading = 'Enviando',
  Pending = 'Pendente',
  Warning = 'Aviso',
  Error = 'Erro',
}

interface EtapaViewProps {
  inspectionId: string
  stepNumber: number
  handleBack: () => void
  handleNext: () => void
}

interface FileMeta {
  aeroId: string | null
  createdAt: Date | null
  latitude: number | null
  longitude: number | null
  thumbnail: string | null
  file: File
}

interface ItemToUpload {
  id: string
  icon: JSX.Element
  uniqueFile: FileMeta | null
  groupedPairs: FileMeta[] | null
  status: ItemStatus
  message: string
}

const EtapaView = ({ inspectionId, stepNumber, handleBack, handleNext }: EtapaViewProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openElement = Boolean(anchorElement)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const folderInputRef = useRef<HTMLInputElement>(null)

  const [mapMarkers, setMapMarkers] = useState<
    {
      id: string
      latitude: number
      longitude: number
      color: string
    }[]
  >([])
  const [showMap, setShowMap] = useState(false)
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false)
  const [tableItems, setTableItems] = useState<ItemToUpload[]>([])
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set())

  const [isLoadingItems, setIsLoadingItems] = useState(false)
  const [currentLoadingItemsName, setCurrentLoadingItemsName] = useState<string>('')
  const [currentLoadingItemsCount, setCurrentLoadingItemsCount] = useState<number>(0)
  const [totalLoadingItemsCount, setTotalLoadingItemsCount] = useState<number>(0)

  const [uploadedItemsCount, setUploadedItemsCount] = useState<number>(0)
  const [uploadingItemsCount, setUploadingItemsCount] = useState<number>(0)
  const [pendingItemsCount, setPendingItemsCount] = useState<number>(0)
  const [warningItemsCount, setWarningItemsCount] = useState<number>(0)
  const [errorItemsCount, setErrorItemsCount] = useState<number>(0)

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedItems(
        new Set(
          tableItems
            .filter(
              (item) => item.status !== ItemStatus.Uploaded && item.status !== ItemStatus.Uploading,
            )
            .map((item) => item.id),
        ),
      )
    } else {
      setSelectedItems(new Set())
    }
  }

  const handleSelectItem = (id: string) => {
    setSelectedItems((prevSelected) => {
      const updatedSelected = new Set(prevSelected)

      if (updatedSelected.has(id)) updatedSelected.delete(id)
      else updatedSelected.add(id)

      return updatedSelected
    })
  }

  const handleRemoveItems = () => {
    setTableItems((prevItems) => prevItems.filter((item) => !selectedItems.has(item.id)))
    setSelectedItems(new Set())
    setOpenRemoveDialog(false)
  }

  const getItemIcon = (filename: string): JSX.Element => {
    if (filename.endsWith('.dwg')) return <LandscapeIcon sx={{ color: 'orange' }} />
    if (filename.endsWith('.pdf')) return <PictureAsPdfIcon sx={{ color: 'red' }} />
    return <CollectionsIcon sx={{ color: 'blue' }} />
  }

  const extractMetadataFromFiles = (filesList: File[]) => {
    setTotalLoadingItemsCount(filesList.length)
    let currentItemsCount = 0
    const promises = filesList.map(async (currentFile) => {
      const metadata = await ExifReader.load(currentFile)

      const latitude = Number(metadata?.GPSLatitude?.description)
      const longitude = Number(metadata?.GPSLongitude?.description)
      const latitudeRef = metadata?.GPSLatitudeRef?.description
      const longitudeRef = metadata?.GPSLongitudeRef?.description
      const dateString = metadata?.DateTimeOriginal?.description
      const lightSource = metadata['Image Width'] && metadata['Image Width'].value < 2000 ? 'T' : 'V'
      const imageThumb = metadata.Thumbnail?.base64 ?? null

      let signedLatitude = null
      let signedLongitude = null
      if (latitude) signedLatitude = latitudeRef === 'South latitude' ? -latitude : latitude
      if (longitude) signedLongitude = longitudeRef === 'West longitude' ? -longitude : longitude
      const aeroString = `AEROID${moment(dateString, 'YYYY:MM:DD HH:mm:ss').format(
        'YYMMDDHHmmss',
      )}${lightSource}`
      const createDate = dateString ? moment(dateString, 'YYYY:MM:DD HH:mm:ss').toDate() : null

      currentItemsCount += 1
      setCurrentLoadingItemsName(currentFile.name)
      setCurrentLoadingItemsCount(currentItemsCount)

      return {
        aeroId: aeroString,
        createdAt: createDate,
        latitude: signedLatitude,
        longitude: signedLongitude,
        thumbnail: imageThumb,
        file: currentFile,
      }
    })

    return Promise.all(promises)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (!files || files.length === 0) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Nenhum arquivo foi selecionado!'),
      })
      return
    }

    setIsLoadingItems(true)

    const validFiles = Array.from(files).filter((file) =>
      stepNumber === 2
        ? file.name.toLowerCase().endsWith('.dwg') || file.name.toLowerCase().endsWith('.pdf')
        : file.type.startsWith('image/'),
    )

    if (validFiles.length !== files.length) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message:
          stepNumber === 2
            ? t('Apenas arquivos com extensão .dwg ou .pdf podem ser selecionados.')
            : t('Apenas arquivos com extensão de imagem podem ser selecionados.'),
      })
    }

    if (stepNumber === 2) {
      const filteredFiles = validFiles.filter(
        (newFile) => !tableItems.some((item) => item.uniqueFile?.file.name === newFile.name),
      )

      if (filteredFiles.length < validFiles.length) {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Arquivos com nomes duplicados foram ignorados!'),
        })
      }

      const mappedItems = filteredFiles.map((currentFile) => ({
        id: uuid(),
        icon: getItemIcon(currentFile.name.toLowerCase()),
        uniqueFile: {
          aeroId: null,
          createdAt: currentFile.lastModified ? moment(currentFile.lastModified).toDate() : null,
          latitude: null,
          longitude: null,
          thumbnail: null,
          file: currentFile,
        },
        groupedPairs: null,
        status: ItemStatus.Pending,
        message: 'Pendente',
      }))

      setTableItems((prevItems) => [...prevItems, ...mappedItems])
      setIsLoadingItems(false)
    }

    if (stepNumber === 5) {
      extractMetadataFromFiles(validFiles).then((resolvedItems) => {
        const filteredItems = resolvedItems.filter(
          (newItem) => !tableItems.some((item) => item.uniqueFile?.aeroId === newItem?.aeroId),
        )

        if (filteredItems.length < resolvedItems.length) {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Arquivos duplicados foram ignorados!'),
          })
        }

        const parsedItems = filteredItems.map((currentItem) => {
          let status: ItemStatus = ItemStatus.Pending
          let message: string = 'Pendente'

          if (!currentItem.latitude || !currentItem.longitude) {
            status = ItemStatus.Warning
            message = 'Coordenadas Inválidas'
          }

          if (!currentItem.aeroId) {
            status = ItemStatus.Warning
            message = 'Identificador Corrompido'
          }

          return {
            id: uuid(),
            icon: getItemIcon(currentItem.file.name.toLowerCase()),
            uniqueFile: currentItem,
            groupedPairs: null,
            status,
            message,
          }
        })

        setTableItems((prevItems) => [...prevItems, ...parsedItems])
        setIsLoadingItems(false)
      })
    }

    if (stepNumber === 3 || stepNumber === 4) {
      extractMetadataFromFiles(validFiles).then((resolvedItems) => {
        const permanentTableItems = tableItems.filter((item) =>
          [ItemStatus.Uploaded, ItemStatus.Uploading].includes(item.status),
        )

        let tempTableFileItems: any
        tempTableFileItems = tableItems.flatMap((item) => {
          if (item.groupedPairs) return [...item.groupedPairs]
          return [item.uniqueFile]
        })

        const noDuplicatedFileItems = resolvedItems.filter(
          (item) => !tempTableFileItems.some((prevItem: any) => prevItem?.aeroId === item?.aeroId),
        )

        if (noDuplicatedFileItems.length < resolvedItems.length) {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Arquivos duplicados foram ignorados!'),
          })
        }

        tempTableFileItems = tableItems
          .filter((item) =>
            [ItemStatus.Pending, ItemStatus.Warning, ItemStatus.Error].includes(item.status),
          )
          .flatMap((item) => {
            if (item.groupedPairs) return [...item.groupedPairs]
            return [item.uniqueFile]
          })

        const joinedAndSortedArrays = [...tempTableFileItems, ...noDuplicatedFileItems].sort((a, b) => {
          if (a?.aeroId && b?.aeroId) {
            return a.aeroId.localeCompare(b.aeroId)
          }
          return 0
        })

        const tempGroupedPairs: ItemToUpload[] = []
        const tempUniqueFiles: ItemToUpload[] = []
        for (let i = 0; i < joinedAndSortedArrays.length; i++) {
          const currentItem = joinedAndSortedArrays[i]
          const nextItem = joinedAndSortedArrays[i + 1]

          if (!currentItem.latitude || !currentItem.longitude) {
            tempUniqueFiles.push({
              id: uuid(),
              icon: getItemIcon(currentItem.file.name.toLowerCase()),
              uniqueFile: currentItem,
              groupedPairs: null,
              status: ItemStatus.Warning,
              message: 'Coordenadas Inválidas',
            })
            continue
          }

          if (!currentItem.aeroId) {
            tempUniqueFiles.push({
              id: uuid(),
              icon: getItemIcon(currentItem.file.name.toLowerCase()),
              uniqueFile: currentItem,
              groupedPairs: null,
              status: ItemStatus.Warning,
              message: 'Identificador Corrompido',
            })
            continue
          }

          if (!currentItem.createdAt) {
            tempUniqueFiles.push({
              id: uuid(),
              icon: getItemIcon(currentItem.file.name.toLowerCase()),
              uniqueFile: currentItem,
              groupedPairs: null,
              status: ItemStatus.Warning,
              message: 'Data Inválida',
            })
            continue
          }

          if (!nextItem) {
            tempUniqueFiles.push({
              id: uuid(),
              icon: getItemIcon(currentItem.file.name.toLowerCase()),
              uniqueFile: currentItem,
              groupedPairs: null,
              status: ItemStatus.Warning,
              message: 'Par Ausente',
            })
            continue
          }

          const currentCreatedAt = moment(currentItem.createdAt)
          const nextCreatedAt = moment(nextItem.createdAt)

          const differenceInMilliseconds = nextCreatedAt.diff(currentCreatedAt) <= 1000
          const isThermalAndRgb = currentItem.aeroId.slice(-1) !== nextItem.aeroId.slice(-1)

          if (differenceInMilliseconds && isThermalAndRgb) {
            tempGroupedPairs.push({
              id: uuid(),
              icon: getItemIcon(currentItem.file.name.toLowerCase()),
              uniqueFile: null,
              groupedPairs: [currentItem, nextItem],
              status: ItemStatus.Pending,
              message: 'Pendente',
            })
            i++
          } else {
            tempUniqueFiles.push({
              id: uuid(),
              icon: getItemIcon(currentItem.file.name.toLowerCase()),
              uniqueFile: currentItem,
              groupedPairs: null,
              status: ItemStatus.Warning,
              message: 'Par Ausente',
            })
          }
        }

        setTableItems([...permanentTableItems, ...tempGroupedPairs, ...tempUniqueFiles])
        setIsLoadingItems(false)
      })
    }
  }

  const loadUploadedItems = async () => {
    let response: any
    if (stepNumber === 2) response = await getInspectionsAll({ InspectionId: inspectionId, FileType: 3 })
    if (stepNumber === 3)
      response = await getInspectionsUpload({ InspectionId: inspectionId, PairType: 1 })
    if (stepNumber === 4)
      response = await getInspectionsUpload({ InspectionId: inspectionId, PairType: 2 })
    if (stepNumber === 5) response = await getInspectionsAll({ InspectionId: inspectionId, FileType: 2 })
    if (!response) return

    if (response.success && (stepNumber === 2 || stepNumber === 5)) {
      setTableItems(
        response.data.map((item: InspectionFileDto) => ({
          id: item.id,
          icon: getItemIcon(item.originalFileName ?? 'Nome Desconhecido'),
          uniqueFile: {
            aeroId: null,
            createdAt: null,
            latitude: null,
            longitude: null,
            thumbnail: null,
            file: new File([], item.originalFileName ?? 'Nome Desconhecido'),
          },
          groupedPairs: null,
          status: ItemStatus.Uploaded,
          message: 'Enviado',
        })),
      )
    }

    if (response.success && (stepNumber === 3 || stepNumber === 4)) {
      setTableItems(
        response.data.map((item: IInspectionUploadResponse) => ({
          id: item.id,
          icon: getItemIcon(item.rgbFilename ?? 'Nome Desconhecido'),
          uniqueFile: null,
          groupedPairs: [
            {
              aeroId: item.thermalAeroId,
              createdAt: null,
              latitude: item.latitude,
              longitude: item.longitude,
              thumbnail: null,
              file: new File([], item.thermalFilename ?? 'Nome Desconhecido'),
            },
            {
              aeroId: item.rgbAeroId,
              createdAt: null,
              latitude: item.latitude,
              longitude: item.longitude,
              thumbnail: null,
              file: new File([], item.rgbFilename ?? 'Nome Desconhecido'),
            },
          ],
          status: ItemStatus.Uploaded,
          message: 'Enviado',
        })),
      )
    }
  }

  const handleUploadItems = async () => {
    const maxConcurrentRequests = stepNumber === 2 || stepNumber === 5 ? 8 : 4

    const updateTableItemStatus = (itemId: string, status: ItemStatus, message: string) => {
      setTableItems((prev) =>
        prev.map((prevItem) => {
          if (itemId === prevItem.id) {
            return { ...prevItem, status, message }
          }
          return prevItem
        }),
      )
    }

    if (stepNumber === 2 || stepNumber === 5) {
      const dataToUpload = tableItems
        .filter((item) => item.status === ItemStatus.Pending || item.status === ItemStatus.Error)
        .map((item) => ({
          id: item.id,
          form: (() => {
            const formData = new FormData()
            formData.append('ValidateSubparksNames', 'false')
            formData.append('InspectionId', inspectionId)
            formData.append('FileType', stepNumber === 2 ? '3' : '2')
            if (item.uniqueFile) formData.append('File', item.uniqueFile.file)
            return formData
          })(),
        }))

      const requestsQueue = dataToUpload.slice()
      let activeRequests = 0

      const processRequest = (nextRequest: any) => {
        activeRequests += 1
        updateTableItemStatus(nextRequest.id, ItemStatus.Uploading, 'Enviando')
        postFile(nextRequest.form)
          .then(() => {
            updateTableItemStatus(nextRequest.id, ItemStatus.Uploaded, 'Enviado')
          })
          .catch(() => {
            updateTableItemStatus(nextRequest.id, ItemStatus.Error, 'Falha no Envio')
          })
          .finally(() => {
            activeRequests -= 1
            runNextRequest()
          })
      }

      const runNextRequest = () => {
        if (requestsQueue.length > 0 && activeRequests < maxConcurrentRequests) {
          const nextRequest = requestsQueue.shift()
          if (nextRequest) {
            processRequest(nextRequest)
          }
        }
      }

      for (let i = 0; i < maxConcurrentRequests; i++) {
        runNextRequest()
      }
    }

    if (stepNumber === 3 || stepNumber === 4) {
      const response = await getGenerateUploadUrls({ InspectionId: inspectionId })
      if (!response.success) {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível gerar os endereços de envio. Tente novamente mais tarde!'),
        })
        return
      }
      const { perpendicularStorageUrl, angledStorageUrl } = response.data
      const storageUrl = stepNumber === 3 ? perpendicularStorageUrl : angledStorageUrl

      const dataToUpload = tableItems
        .filter((item) => item.status === ItemStatus.Pending || item.status === ItemStatus.Error)
        .map((item) => {
          return {
            id: item.id,
            headers: {
              'x-ms-blob-type': 'BlockBlob',
              'x-ms-meta-shot_date': item.groupedPairs?.[0]?.createdAt
                ? moment(item.groupedPairs[0].createdAt).format('YYYY/MM/DD HH:mm:ss')
                : '',
              'x-ms-meta-latitude': item.groupedPairs?.[0]?.latitude?.toString() ?? '',
              'x-ms-meta-longitude': item.groupedPairs?.[0]?.longitude?.toString() ?? '',
              'Content-Type': 'image/jpeg',
            },
            files: item.groupedPairs
              ? [
                  {
                    storageUrl: storageUrl.replace(
                      'FRONTENDIMAGEPATH',
                      `${item.groupedPairs[0].aeroId}-${item.groupedPairs[0].file.name}`,
                    ),
                    file: item.groupedPairs[0].file,
                  },
                  {
                    storageUrl: storageUrl.replace(
                      'FRONTENDIMAGEPATH',
                      `${item.groupedPairs[1].aeroId}-${item.groupedPairs[1].file.name}`,
                    ),
                    file: item.groupedPairs[1].file,
                  },
                ]
              : [],
          }
        })

      const requestsQueue = dataToUpload.slice()
      let activeRequests = 0

      const processRequest = (nextRequest: any) => {
        activeRequests += 1
        updateTableItemStatus(nextRequest.id, ItemStatus.Uploading, 'Enviando')
        const promises: Promise<any>[] = []
        nextRequest.files.forEach((file: any) => {
          promises.push(
            axios.put(file.storageUrl, file.file, {
              headers: nextRequest.headers,
            }),
          )
        })

        Promise.all(promises)
          .then(() => {
            updateTableItemStatus(nextRequest.id, ItemStatus.Uploaded, 'Enviado')
          })
          .catch(() => {
            updateTableItemStatus(nextRequest.id, ItemStatus.Error, 'Falha no Envio')
          })
          .finally(() => {
            activeRequests -= 1
            runNextRequest()
          })
      }

      const runNextRequest = () => {
        if (requestsQueue.length === 0 && activeRequests === 0) {
          postAnalyzeStorageAsync({
            InspectionId: inspectionId,
            UploadPairType: stepNumber === 3 ? EUploadPairType.Perpendicular : EUploadPairType.Angled45,
          })
          return
        }

        if (requestsQueue.length > 0 && activeRequests < maxConcurrentRequests) {
          const nextRequest = requestsQueue.shift()
          if (nextRequest) {
            processRequest(nextRequest)
          }
        }
      }

      for (let i = 0; i < maxConcurrentRequests; i++) {
        runNextRequest()
      }
    }
  }

  const loadMapMarkers = () => {
    const loadedMapMarkers = tableItems
      .filter((item) =>
        [ItemStatus.Uploaded, ItemStatus.Pending, ItemStatus.Error].includes(item.status),
      )
      .map((item) => {
        let color
        if (item.status === ItemStatus.Uploaded) color = 'green'
        if (item.status === ItemStatus.Pending) color = 'cyan'
        if (item.status === ItemStatus.Error) color = 'red'

        let latitude
        let longitude
        if (stepNumber === 5) {
          latitude = item.uniqueFile?.latitude
          longitude = item.uniqueFile?.longitude
        } else {
          latitude = item.groupedPairs?.[0]?.latitude
          longitude = item.groupedPairs?.[0]?.longitude
        }

        return {
          id: item.id,
          latitude: latitude as number,
          longitude: longitude as number,
          color: color as string,
        }
      })

    if (loadedMapMarkers.length > 0) {
      setMapMarkers(loadedMapMarkers)
    }
  }

  const getStatusPercentage = (statusCount: number) => {
    const totalItemsCount =
      uploadedItemsCount + uploadingItemsCount + pendingItemsCount + warningItemsCount + errorItemsCount
    return totalItemsCount === 0 ? '0.00%' : `${((statusCount / totalItemsCount) * 100).toFixed(2)}%`
  }

  const orderTableItemsByStatus = () => {
    const statuses = [
      ItemStatus.Uploaded,
      ItemStatus.Uploading,
      ItemStatus.Pending,
      ItemStatus.Warning,
      ItemStatus.Error,
    ]

    const sortedTableItems = [...tableItems].sort((a, b) => {
      const aIndex = statuses.indexOf(a.status)
      const bIndex = statuses.indexOf(b.status)
      return aIndex - bIndex
    })

    setTableItems(sortedTableItems)
  }

  useEffect(() => {
    setUploadedItemsCount(tableItems.filter((item) => item.status === ItemStatus.Uploaded).length)
    setUploadingItemsCount(tableItems.filter((item) => item.status === ItemStatus.Uploading).length)
    setPendingItemsCount(tableItems.filter((item) => item.status === ItemStatus.Pending).length)
    setWarningItemsCount(tableItems.filter((item) => item.status === ItemStatus.Warning).length)
    setErrorItemsCount(tableItems.filter((item) => item.status === ItemStatus.Error).length)
  }, [tableItems])

  useEffect(() => {
    setTableItems([])
    setMapMarkers([])
    setUploadedItemsCount(0)
    setUploadingItemsCount(0)
    setPendingItemsCount(0)
    setWarningItemsCount(0)
    setErrorItemsCount(0)
    setShowMap(false)
    loadUploadedItems()
  }, [stepNumber])

  useEffect(() => {
    if (uploadingItemsCount === 0) {
      orderTableItemsByStatus()
      loadMapMarkers()
    }
  }, [tableItems.length, uploadingItemsCount])

  return (
    <Stack
      sx={{
        margin: '24px 5%',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '16px',
        border: '1px solid #C4C4C4',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6" fontWeight="700" align="center">
          {stepNumber === 2 && t('Diagrama Elétrico (DWG ou PDF)')}
          {stepNumber === 3 && t('Imagens RGB e IR (90 graus)')}
          {stepNumber === 4 && t('Imagens RGB e IR (45 graus)')}
          {stepNumber === 5 && t('Ortomosaico em RGB')}
        </Typography>
        {stepNumber !== 2 && (
          <Button
            variant="contained"
            startIcon={showMap ? <UploadFileIcon /> : <MapIcon />}
            onClick={() => setShowMap(!showMap)}
            disabled={mapMarkers.length === 0 || uploadingItemsCount > 0}
          >
            {showMap ? 'Arquivos' : 'Mapa'}
          </Button>
        )}
      </Box>
      <Box>
        {showMap ? (
          <MapLocationImageViewer markers={mapMarkers} />
        ) : (
          <Paper style={{ height: 750, width: '100%' }}>
            <LinearLoadingBackdrop
              isOpen={isLoadingItems}
              backdropTitle="Processando os Arquivos..."
              currentItem={currentLoadingItemsName}
              currentCount={currentLoadingItemsCount}
              totalCount={totalLoadingItemsCount}
            />
            <TableVirtuoso
              data={tableItems}
              components={VirtuosoTableComponents}
              fixedHeaderContent={() =>
                fixedHeaderContent(
                  tableItems,
                  selectedItems,
                  handleSelectAll,
                  pendingItemsCount,
                  warningItemsCount,
                  errorItemsCount,
                )
              }
              itemContent={(_index, row) => rowContent(_index, row, selectedItems, handleSelectItem)}
            />
          </Paper>
        )}
      </Box>
      <Paper sx={{ paddingX: '20px', paddingY: '10px', border: '1px solid #C4C4C4' }}>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
          sx={{
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <TaskAltIcon sx={{ color: 'green' }} />
            <Typography variant="body1" fontWeight="bold">
              Enviado
            </Typography>
            <Typography>
              {uploadedItemsCount} | <i>{getStatusPercentage(uploadedItemsCount)}</i>
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress size={20} variant="determinate" value={75} sx={{ color: 'blue' }} />
            <Typography variant="body1" fontWeight="bold">
              Enviando
            </Typography>
            <Typography>
              {uploadingItemsCount} | <i>{getStatusPercentage(uploadingItemsCount)}</i>
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <HourglassTopIcon sx={{ color: 'cyan' }} />
            <Typography variant="body1" fontWeight="bold">
              Pendente
            </Typography>
            <Typography>
              {pendingItemsCount} | <i>{getStatusPercentage(pendingItemsCount)}</i>
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <ReportGmailerrorredIcon sx={{ color: 'orange' }} />
            <Typography variant="body1" fontWeight="bold">
              Aviso
            </Typography>
            <Typography>
              {warningItemsCount} | <i>{getStatusPercentage(warningItemsCount)}</i>
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <HighlightOffIcon sx={{ color: 'red' }} />
            <Typography variant="body1" fontWeight="bold">
              Erro
            </Typography>
            <Typography>
              {errorItemsCount} | <i>{getStatusPercentage(errorItemsCount)}</i>
            </Typography>
          </Box>
        </Stack>
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
        <Box>
          <Button
            variant="outlined"
            onClick={handleBack}
            disabled={selectedItems.size > 0 || uploadingItemsCount > 0 || showMap}
          >
            {t('Voltar')}
          </Button>
        </Box>
        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <Button
            variant="contained"
            component="label"
            aria-haspopup="true"
            startIcon={<UploadIcon />}
            disabled={selectedItems.size > 0 || uploadingItemsCount > 0 || showMap}
            aria-controls={openElement ? 'basic-menu' : undefined}
            aria-expanded={openElement ? 'true' : undefined}
            onClick={(event) => {
              setAnchorElement(event.currentTarget)
            }}
          >
            {t('Selecionar')}
          </Button>
          <input
            ref={folderInputRef}
            type="file"
            webkitdirectory="true"
            style={{ display: 'none' }}
            onChange={handleInputChange}
          />
          <input
            ref={fileInputRef}
            type="file"
            accept={stepNumber === 2 ? '.dwg,.pdf' : 'image/*'}
            multiple
            style={{ display: 'none' }}
            onChange={handleInputChange}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorElement}
            open={openElement}
            onClose={() => setAnchorElement(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem
              onClick={() => {
                folderInputRef.current?.click()
                setAnchorElement(null)
              }}
            >
              <FolderOpenOutlinedIcon sx={{ marginRight: '10px' }} />
              {t('Pasta')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                fileInputRef.current?.click()
                setAnchorElement(null)
              }}
            >
              <InsertDriveFileOutlinedIcon sx={{ marginRight: '10px' }} />
              {t('Arquivo')}
            </MenuItem>
          </Menu>
          <Button
            variant="contained"
            startIcon={<DeleteSweepIcon />}
            onClick={() => setOpenRemoveDialog(true)}
            disabled={showMap || selectedItems.size === 0}
            sx={{ backgroundColor: '#FF0000', '&:hover': { backgroundColor: '#FF3737' } }}
          >
            {t('Remover')}
          </Button>
          {pendingItemsCount > 0 || errorItemsCount > 0 ? (
            <Button
              variant="contained"
              startIcon={<CloudUploadIcon />}
              disabled={selectedItems.size > 0 || uploadingItemsCount > 0 || showMap}
              onClick={handleUploadItems}
            >
              {t('Enviar')}
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                if (stepNumber === 5) return navigate(-1)
                handleNext()
              }}
            >
              {stepNumber === 5 ? t('Concluir') : t('Avançar')}
            </Button>
          )}
        </Box>
      </Box>
      <ModalCustom
        open={openRemoveDialog}
        onClose={() => setOpenRemoveDialog(false)}
        onClick={() => handleRemoveItems()}
        title={t('Remover Itens') + '?'}
        description={t(
          'Tem certeza que deseja remover os itens selecionados? Após a confirmação, não há como voltar atrás.',
        )}
        confirmationButtonText={t('Excluir')}
        textDeclineButton={t('Cancelar')}
      />
    </Stack>
  )
}

export default EtapaView

const VirtuosoTableComponents: TableComponents<ItemToUpload> = {
  Scroller: forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />,
  TableHead: forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableHead sx={{ backgroundColor: '#dbe1ee', zIndex: 1 }} {...props} ref={ref} />
  )),
  TableRow,
  TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />),
}

function fixedHeaderContent(
  itemsToUpload: ItemToUpload[],
  selectedItems: Set<string>,
  handleSelectAll: (event: ChangeEvent<HTMLInputElement>) => void,
  pendingItemsCount: number,
  warningItemsCount: number,
  errorItemsCount: number,
) {
  const filteredItems = itemsToUpload.filter(
    (item) => ![ItemStatus.Uploaded, ItemStatus.Uploading].includes(item.status),
  )

  return (
    <TableRow>
      <TableCell variant="head" align="center" sx={{ fontWeight: 'bold', width: '50px' }}>
        <Checkbox
          checked={selectedItems.size > 0 && selectedItems.size === filteredItems.length}
          indeterminate={selectedItems.size > 0 && selectedItems.size < filteredItems.length}
          onChange={handleSelectAll}
          disabled={pendingItemsCount === 0 && warningItemsCount === 0 && errorItemsCount === 0}
        />
      </TableCell>
      <TableCell variant="head" sx={{ fontWeight: 'bold' }}>
        Arquivo
      </TableCell>
      <TableCell variant="head" align="right" sx={{ fontWeight: 'bold', width: '200px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '2.5px' }}>
          <span>Mensagem</span>
          <Tooltip
            sx={{ cursor: 'help' }}
            title={<StatusTooltips />}
            placement="bottom"
            arrow
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 4],
                  },
                },
              ],
              sx: {
                '& .MuiTooltip-tooltip': {
                  backgroundColor: '#EFF3F9',
                  color: 'rgba(0, 0, 0, 0.87)',
                },
              },
            }}
          >
            <InfoIcon fontSize="small" color="info" />
          </Tooltip>
        </Box>
      </TableCell>
      <TableCell variant="head" align="right" sx={{ fontWeight: 'bold', width: '75px' }}>
        Status
      </TableCell>
    </TableRow>
  )
}

function rowContent(
  _index: number,
  row: ItemToUpload,
  selectedItems: Set<string>,
  handleSelectItem: (id: string) => void,
) {
  return (
    <>
      <TableCell align="center">
        <Checkbox
          checked={selectedItems.has(row.id)}
          onChange={() => handleSelectItem(row.id)}
          sx={{
            visibility: [ItemStatus.Uploaded, ItemStatus.Uploading].includes(row.status)
              ? 'hidden'
              : undefined,
          }}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        <span style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          {row.icon}
          {row.groupedPairs ? (
            <>
              {row.groupedPairs[0].file.name}
              <br />
              {row.groupedPairs[1].file.name}
            </>
          ) : (
            <>{row.uniqueFile?.file.name}</>
          )}
        </span>
      </TableCell>
      <TableCell sx={{ width: '200px', textAlign: 'right' }}>{row.message}</TableCell>
      <TableCell align="right" sx={{ width: '75px', textAlign: 'center' }}>
        {row.status === ItemStatus.Uploaded && <TaskAltIcon sx={{ color: 'green' }} />}
        {row.status === ItemStatus.Uploading && <CircularProgress size={20} sx={{ color: 'blue' }} />}
        {row.status === ItemStatus.Pending && <HourglassTopIcon sx={{ color: 'cyan' }} />}
        {row.status === ItemStatus.Warning && <ReportGmailerrorredIcon sx={{ color: 'orange' }} />}
        {row.status === ItemStatus.Error && <HighlightOffIcon sx={{ color: 'red' }} />}
      </TableCell>
    </>
  )
}

export const StatusTooltips = () => {
  const statusMessages = [
    {
      icon: <TaskAltIcon sx={{ color: 'green' }} />,
      title: 'Enviado',
      description: 'Item enviado e sendo processado nos servidores. Impossível excluir.',
    },
    {
      icon: <CircularProgress size={20} variant="determinate" value={75} sx={{ color: 'blue' }} />,
      title: 'Enviando',
      description: 'Item sendo enviado para os servidores. Impossível excluir.',
    },
    {
      icon: <HourglassTopIcon sx={{ color: 'cyan' }} />,
      title: 'Pendente',
      description: 'Item selecionado pelo usuário e pronto para envio. Pode ser excluído.',
    },
    {
      icon: <ReportGmailerrorredIcon sx={{ color: 'orange' }} />,
      title: 'Coordenadas Inválidas',
      description:
        'Coordenadas não puderam ser extraídas dos metadados do arquivo pelo sistema. Pode ser excluído.',
    },
    {
      icon: <ReportGmailerrorredIcon sx={{ color: 'orange' }} />,
      title: 'Data Inválida',
      description: 'Data não pôde ser extraída e/ou processada pelo sistema. Pode ser excluído.',
    },
    {
      icon: <ReportGmailerrorredIcon sx={{ color: 'orange' }} />,
      title: 'Identificador Corrompido',
      description: 'Identificador não pôde ser gerado pelo sistema. Pode ser excluído.',
    },
    {
      icon: <ReportGmailerrorredIcon sx={{ color: 'orange' }} />,
      title: 'Par Ausente',
      description: 'Par não encontrado pelo sistema. Pode ser excluído.',
    },
    {
      icon: <HighlightOffIcon sx={{ color: 'red' }} />,
      title: 'Falha no Envio',
      description: 'Erro ao enviar o item. Pode ser excluído. Pode ser reenviado.',
    },
  ]

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        wordWrap: 'break-word',
        whiteSpace: 'normal',
        padding: '10px',
      }}
    >
      {statusMessages.map((item) => (
        <Box key={item.title} sx={{ marginTop: '10px' }}>
          <Typography sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: '5px' }}>
            {item.icon} {item.title}
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>{item.description}</Typography>
        </Box>
      ))}
    </Box>
  )
}
