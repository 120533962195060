import atlas from 'azure-maps-control'
import { IAzureMapOptions } from 'react-azure-maps'
import RuntimeEnvironment from '~/config/RuntimeEnvironment'

export const defaultAzureMapOptions: IAzureMapOptions = {
  authOptions: {
    authType: atlas.AuthenticationType.subscriptionKey,
    subscriptionKey: RuntimeEnvironment.REACT_APP_AZURE_MAPS_KEY,
  },

  view: 'auto',
  style: 'satellite_road_labels',
  language: 'pt-BR',

  preserveDrawingBuffer: true,
  enableAccessibility: false, // Desabilita recursos de acessibilidade se não necessários
  renderWorldCopies: false, // Evita renderização de cópias do mundo
  showLogo: true, // Remove elementos UI desnecessários
  showFeedbackLink: false,

  // Otimizações de renderização
  maxBounds: undefined, // Remove limites desnecessários
  bearing: 0, // Fixa a orientação do mapa
  pitch: 0, // Mantém visualização plana para melhor performance
  styleOptions: {
    showBuildingModels: false, // Desativa modelos 3D
    showLabelBubbles: false, // Desativa bolhas de rótulo
    //textStyle: 'name', // Simplifica estilo de texto
    //  labelColor: 'standard', // Usa cores padrão para labels
  },

  // Otimizações de carga
  transformRequest: undefined, // Remove transformação de requisições desnecessária
  fog: false, // Desativa efeitos de névoa
  terrain: false, // Desativa terreno 3D

  // Configurações de desempenho
  fadeDuration: 0, // Reduz animações
  crossSourceCollisions: false, // Desativa colisões entre fontes diferentes
  optimizeForTerrain: false, // Desativa otimização para terreno

  progressiveLoading: true,
  center: [-45.178408, -7.663791],
  zoom: 1.5,
}
