import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Switch,
  FormControlLabel,
  Box,
} from '@mui/material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { useTranslation } from 'react-i18next'
import * as S from '../../styles'
import { SelectOption } from '~/pages/Dashboard/Reports/types'
import { useSubparksSimple } from '~/hooks/useSubparksSimple'
import { useNavigate } from 'react-router-dom'
import { menuProps, selectStyles } from './selectStyles'

interface MapHeaderProps {
  showDetails: boolean
  setShowDetails: (show: boolean) => void
  showFilterModal: boolean
  setShowFilterModal: (show: boolean) => void
  selectedSiteId: string
  setSelectedSiteId: (id: string) => void
  selectedInspectionId: string
  setSelectedInspectionId: (id: string) => void
  siteOptions: SelectOption[]
  inspectionOptions: SelectOption[]
  onChangeSelectedSubparks: (
    subparks: SelectOption | SelectOption[] | null,
    allSubparks: SelectOption[],
  ) => void
  setShowSidebar: (show: boolean) => void
  setShouldDrawModulePolygons: Dispatch<SetStateAction<boolean>>
  shouldDrawModulePolygons: boolean
  shouldShowOrthomosaic: boolean
  setShouldShowOrthomosaic: Dispatch<SetStateAction<boolean>>
  isLoadingOrthomosaic: boolean
  disableOrthomosaic: boolean
}

const MapHeader: React.FC<MapHeaderProps> = ({
  showDetails,
  setShowDetails,
  showFilterModal,
  setShowFilterModal,
  selectedSiteId,
  setSelectedSiteId,
  selectedInspectionId,
  setSelectedInspectionId,
  siteOptions,
  inspectionOptions,
  setShowSidebar,
  onChangeSelectedSubparks,
  setShouldDrawModulePolygons,
  shouldDrawModulePolygons,
  shouldShowOrthomosaic,
  setShouldShowOrthomosaic,
  isLoadingOrthomosaic,
  disableOrthomosaic,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { loadSimpleSubparksSimpleBySiteId, subparksSimpleOptions } = useSubparksSimple()

  const [subparksOptionsList, setSubparksOptionsList] = useState<SelectOption[]>([])
  const [selectedSubparks, setSelectedSubparks] = useState<string[]>([])

  const selectedSubparksOptionsList = useMemo(
    () => subparksOptionsList.filter((option) => selectedSubparks.includes(option.value)),
    [subparksOptionsList, selectedSubparks],
  )

  const selectedSiteOption = useMemo(
    () => siteOptions.find((option) => option.value === selectedSiteId),
    [siteOptions, selectedSiteId],
  )

  const selectedInspectionOption = useMemo(
    () => inspectionOptions.find((option) => option.value === selectedInspectionId),
    [inspectionOptions, selectedInspectionId],
  )

  const renderSubparksValue = useCallback(() => {
    return selectedSubparksOptionsList
      .map((e) => {
        const l = e.label.trim()
        return l.slice(0, 4).trim() + '_' + l.slice(-4).trim()
      })
      .join(', ')
  }, [selectedSubparksOptionsList])

  const handleSubparksChange = (event: any) => {
    const {
      target: { value },
    } = event

    const selectedValues = typeof value === 'string' ? value.split(',') : value
    setSelectedSubparks(selectedValues)
  }

  const handleSiteChange = (event: any) => {
    const siteId = event.target.value
    setSelectedInspectionId('')

    if (siteId) {
      setSelectedSiteId(siteId)

      setSelectedSubparks([])
    } else {
      setSelectedSiteId('')
      setSubparksOptionsList([])
      setSelectedSubparks([])
    }
  }

  const handleToggleShouldDrawModulePolygons = (event: any) => {
    setShouldDrawModulePolygons(event.target.checked)
  }

  useEffect(() => {
    setSubparksOptionsList(subparksSimpleOptions)

    if (subparksSimpleOptions.length > 0) {
      const allSubparkIds = subparksSimpleOptions.map((option) => option.value)
      setSelectedSubparks(allSubparkIds)
    }
  }, [subparksSimpleOptions])

  useEffect(() => {
    onChangeSelectedSubparks(selectedSubparksOptionsList, subparksOptionsList)
  }, [selectedSubparksOptionsList])

  useEffect(() => {
    if (selectedSiteId) {
      loadSimpleSubparksSimpleBySiteId(selectedSiteId)
    }
  }, [selectedSiteId])

  const showTextOrto = () => {
    if (disableOrthomosaic) {
      return t('Nenhuma base disponível')
    }

    if (shouldShowOrthomosaic) {
      return `${t('Desabilitar Base')} ${isLoadingOrthomosaic ? t('(Aguarde...)') : ''}`
    }
    return t('Habilitar Base')
  }

  return (
    <div>
      {' '}
      <S.FormWrapper>
        <FormControl sx={selectStyles}>
          <InputLabel id="site-select-label">{t('Selecione o Parque solar')}</InputLabel>
          <Select
            fullWidth
            labelId="site-select-label"
            id="site-select"
            value={selectedSiteId}
            onChange={handleSiteChange}
            input={<OutlinedInput label={t('Selecione o Parque solar')} />}
            renderValue={() => selectedSiteOption?.label ?? ''}
            MenuProps={menuProps as any}
          >
            {siteOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={selectStyles}>
          <InputLabel id="multiple-checkbox-subparks-label">{t('Selecione os Subparque')}</InputLabel>
          <Select
            fullWidth
            labelId="multiple-checkbox-subparks-label"
            id="multiple-checkbox-subparks"
            multiple
            value={selectedSubparks}
            onChange={handleSubparksChange}
            input={<OutlinedInput label={t('Selecione os Subparque')} />}
            renderValue={renderSubparksValue}
            disabled={selectedSiteId === ''}
            MenuProps={menuProps as any}
          >
            {subparksOptionsList.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={selectedSubparks.includes(option.value)} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={selectStyles}>
          <InputLabel id="inspection-select-label">{t('Selecione a Inspeção')}</InputLabel>
          <Select
            fullWidth
            labelId="inspection-select-label"
            id="inspection-select"
            value={selectedInspectionId}
            onChange={(event) => {
              setSelectedInspectionId(event.target.value)
            }}
            input={<OutlinedInput label={t('Selecione a Inspeção')} />}
            renderValue={() => selectedInspectionOption?.label ?? ''}
            disabled={selectedSiteId === ''}
            MenuProps={menuProps as any}
          >
            {inspectionOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {!showDetails && (
          <Button
            variant="outlined"
            onClick={() => setShowFilterModal(!showFilterModal)}
            disabled={selectedInspectionId === ''}
          >
            <FilterAltIcon />
          </Button>
        )}

        <Button
          variant="outlined"
          onClick={() => navigate(`/inspection/${selectedInspectionId}`)}
          sx={{ minWidth: '120px' }}
          disabled={selectedInspectionId === ''}
        >
          Dashboard
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            setShowDetails(!showDetails)
            setShowSidebar(false)
          }}
          sx={{ minWidth: '100px' }}
          disabled={selectedInspectionId === ''}
        >
          {!showDetails ? t('Detalhes') : t('Mapa')}
        </Button>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={shouldDrawModulePolygons}
                  onChange={handleToggleShouldDrawModulePolygons}
                  color="primary"
                />
              }
              label={shouldDrawModulePolygons ? t('Desabilitar Módulos') : t('Habilitar Módulos')}
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  disabled={disableOrthomosaic}
                  checked={disableOrthomosaic ? false : shouldShowOrthomosaic}
                  onChange={(event) => setShouldShowOrthomosaic(event.target.checked)}
                  color="primary"
                />
              }
              label={`${showTextOrto()}`}
            />
          </Box>
        </Box>
      </S.FormWrapper>
    </div>
  )
}

export default MapHeader
