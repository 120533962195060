import { useState, useEffect } from 'react'
import { SelectOption } from '~/pages/Dashboard/Reports/types'
import { AnomaliesCountDTO } from '~/services/Anomaly/types'
import { getAnomalyColor } from '~/utils/Anomalies'

export const useAnomaliesData = (subparks: SelectOption | SelectOption[] | null | '*') => {
  const [anomaliesStringsData, setAnomaliesStringsData] = useState<any>(null)
  const [anomaliesModulesData, setAnomaliesModulesData] = useState<any>(null)
  const [anomaliesOrphansData, setAnomaliesOrphansData] = useState<any>(null)
  const [rawAnomaliesData, setRawAnomaliesData] = useState<any>(null)
  const [anomaliesCountByType, setAnomaliesCountByType] = useState<AnomaliesCountDTO[]>([])

  const loadAnomalies = (response: any) => {
    // Armazena os dados brutos para poder filtrar posteriormente
    setRawAnomaliesData(response)

    return processAnomaliesData(response.anomalies)
  }

  // Função para processar os dados das anomalias conforme os subparks selecionados
  const processAnomaliesData = (anomalies: any[]) => {
    let tempStrings: any = {
      type: 'FeatureCollection',
      features: [],
    }
    let tempModules: any = {
      type: 'FeatureCollection',
      features: [],
    }
    let tempOrphans: any = {
      type: 'FeatureCollection',
      features: [],
    }

    // Determina quais subparks foram selecionados
    const selectedSubparkIds = getSelectedSubparkIds(subparks)

    // Objeto para contar anomalias por tipo
    const countByType: Record<string, number> = {}

    // Filtra as anomalias com base nos subparks selecionados
    const filteredAnomalies = anomalies.filter((anomaly: any) => {
      return subparks === '*' || selectedSubparkIds.includes(anomaly.subparkId?.toString())
    })

    // Conta as anomalias por tipo
    filteredAnomalies.forEach((anomaly: any) => {
      const typeName = anomaly.originalTypeName || 'Unknown'
      if (countByType[typeName]) {
        countByType[typeName]++
      } else {
        countByType[typeName] = 1
      }
    })

    // Converte o objeto de contagem para o formato esperado pelo estado
    const anomalyCountsArray = Object.entries(countByType).map(([name, count]) => ({
      name,
      count,
    }))

    // Atualiza o estado com a contagem de anomalias por tipo
    setAnomaliesCountByType(anomalyCountsArray as any)

    // Processa as anomalias filtradas para gerar os dados GeoJSON
    filteredAnomalies.forEach((anomaly: any) => {
      const properties = {
        anomalyId: anomaly.id,
        anomalyCode: anomaly.code,
        anomalyType: anomaly.originalTypeName,
        anomalyColor: getAnomalyColor(anomaly.originalTypeName),
        anomalyStatus: anomaly.status,
        anomalyName: anomaly.name,
        anomalyImage: anomaly.labelledImageUrl,
        anomalyLatitude: anomaly.latitude,
        anomalyLongitude: anomaly.longitude,
        anomalyManualLatitude: anomaly.manualLatitude,
        anomalyManualLongitude: anomaly.manualLongitude,
      }

      if (anomaly.moduleCoordinates.length > 0) {
        tempModules.features.push({
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [anomaly.moduleCoordinates.map((coord: any) => [coord[0], coord[1]])],
          },
          properties: properties,
        })
        if (anomaly.typeId === 1 || anomaly.typeId === 2) {
          tempStrings.features.push({
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [anomaly.stringModuleCoordinates.map((coord: any) => [coord[0], coord[1]])],
            },
            properties: {
              stringId: anomaly.stringModuleId,
            },
          })
        }
      } else {
        tempOrphans.features.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [anomaly.longitude, anomaly.latitude],
          },
          properties: properties,
        })
      }
    })

    setAnomaliesStringsData(tempStrings)
    setAnomaliesModulesData(tempModules)
    setAnomaliesOrphansData(tempOrphans)

    return { tempStrings, tempModules, tempOrphans }
  }

  // Função para extrair os IDs dos subparks selecionados
  const getSelectedSubparkIds = (subparks: SelectOption | SelectOption[] | null | '*'): string[] => {
    if (subparks === '*' || subparks === null) {
      return []
    }

    if (Array.isArray(subparks)) {
      return subparks.map((sp) => sp.value.toString())
    }

    return [subparks.value.toString()]
  }

  useEffect(() => {
    if (rawAnomaliesData) {
      processAnomaliesData(rawAnomaliesData.anomalies)
    }
  }, [subparks])

  return {
    anomaliesStringsData,
    anomaliesModulesData,
    anomaliesOrphansData,
    anomaliesCountByType,
    loadAnomalies,
  }
}
