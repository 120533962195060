import { getJson } from '../RequestService'

const defaultRoot = 'park/subparkssimple'

export async function getSimpleSubparksSimpleBySiteId(id: string) {
  let url = `${defaultRoot}`
  if (id) {
    url += `?SiteId=${id}`
  }
  return await getJson<{ name: string; id: number }[]>(url)
}
