import { useState, useEffect } from 'react'
import { getSiteId } from '~/services/Site'
import { ISite } from '~/services/Site/types'

export const useFetchSite = (initialId?: string) => {
  const [siteId, setSiteId] = useState(initialId)
  const [site, setSite] = useState<ISite | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const fetchSite = async (id?: string) => {
    if (!id) return null

    setLoading(true)
    setError(null)

    try {
      const siteData = await getSiteId(id)
      setSite(siteData.data)
      if (id) setSiteId(id)
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to fetch site'))
      setSite(null)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchSite(initialId)
  }, [initialId])

  return {
    site,
    loading,
    error,
    currentSiteId: siteId,
    fetchSite,
  }
}
