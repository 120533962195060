import { useCallback } from 'react'
import ToastNotification from '~/components/ToastNotification'
import { useTranslation } from 'react-i18next'

interface MapFeature {
  dataSource: {
    id: string
  }
  data: {
    properties: {
      stringId: number
      anomalyId: string
    }
  }
}

interface UseAnomalyMapEditProps {
  isEditingCoordinates: boolean
  editingAnomaly: any
  showAnomalyDetails: (anomalyId: string) => void
  changeAnomalyLocation: (params: {
    anomalyId: string
    latitude: number
    longitude: number
    shouldReset: boolean
    stringId?: number
  }) => void
  onEditingComplete: () => void
}

export const useAnomalyMapEdit = ({
  isEditingCoordinates,
  editingAnomaly,
  showAnomalyDetails,
  changeAnomalyLocation,
  onEditingComplete,
}: UseAnomalyMapEditProps) => {
  const { t } = useTranslation()

  const findFeatureByDataSourceId = useCallback((shapes: MapFeature[] | undefined, id: string) => {
    return shapes?.find((shape) => shape.dataSource.id === id)?.data
  }, [])

  const handleAnomalyMapEdit = useCallback(
    (event: any) => {
      const [longitude, latitude] = event.position
      const validShapes = event?.shapes?.filter((shape: MapFeature) => shape?.dataSource?.id) || []

      const stringFeature = findFeatureByDataSourceId(validShapes, 'strings')

      const anomalyFeature = findFeatureByDataSourceId(validShapes, 'anomalies')
      const orphanFeature = findFeatureByDataSourceId(validShapes, 'orphan-anomalies')

      if (isEditingCoordinates) {
        if (validShapes.length >= 2 && !findFeatureByDataSourceId(validShapes, 'modules')) {
          const errorMessage = anomalyFeature
            ? 'Não é possível mudar a localização de uma anomalia para um módulo com anomalia, tente novamente.'
            : 'É necessário aumentar mais o zoom para selecionar a localização da anomalia, tente novamente.'

          ToastNotification({
            id: 'error',
            type: 'error',
            message: t(errorMessage),
          })
          onEditingComplete()
          return
        }

        if (!stringFeature) {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t(
              'Não é possível mudar a localização de uma anomalia para fora de um módulo, tente novamente.',
            ),
          })
          onEditingComplete()
          return
        }

        changeAnomalyLocation({
          anomalyId: editingAnomaly.id,
          latitude,
          longitude,
          shouldReset: false,
          stringId: stringFeature.properties.stringId,
        })
        onEditingComplete()
        return
      }

      const anomalyId = orphanFeature?.properties.anomalyId ?? anomalyFeature?.properties.anomalyId
      if (anomalyId) {
        showAnomalyDetails(anomalyId)
      }
    },
    [
      isEditingCoordinates,
      editingAnomaly,

      showAnomalyDetails,
      changeAnomalyLocation,
      onEditingComplete,
      findFeatureByDataSourceId,
    ],
  )

  return { handleAnomalyMapEdit }
}
