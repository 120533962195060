import atlas from 'azure-maps-control'
import { useContext } from 'react'
import {
  AzureMapDataSourceProvider,
  AzureMapLayerProvider,
  AzureMapsContext,
  IAzureMapLayerEvent,
  IAzureMapsContextProps,
} from 'react-azure-maps'
import { filterCloseFeatures } from '../../utils/filterCloseFeatures'

export default function StringsDataSource({
  data,
  layerEvents,
}: Readonly<{
  data: null | atlas.data.FeatureCollection
  layerEvents: Partial<IAzureMapLayerEvent>
}>) {
  const { mapRef } = useContext<IAzureMapsContextProps>(AzureMapsContext)

  if (!data || !mapRef) return null

  const camera = mapRef.getCamera()
  const minZoom = 15
  const textMinZoom = 18.5

  if ((camera.zoom ?? 0) < minZoom) return null

  let collection = data

  if ((camera.zoom ?? 0) <= textMinZoom) {
    collection = {
      ...data,
      features: filterCloseFeatures(data.features, camera?.zoom as number, camera?.center?.[1] ?? 0),
    }
  }

  return (
    <AzureMapDataSourceProvider id="strings" collection={collection}>
      <AzureMapLayerProvider
        id="strings-polygons"
        options={{
          fillColor: 'transparent',
          strokeColor: '#0078D7',
          strokeWidth: 3,
          minZoom,
        }}
        type="LineLayer"
      />
      <AzureMapLayerProvider
        id="strings-polygons-for-click"
        options={{
          fillColor: 'transparent',
          fillPattern: 0,

          minZoom,
        }}
        type="PolygonLayer"
        events={layerEvents}
      />
      <AzureMapLayerProvider
        id="strings-labels"
        options={{
          iconOptions: {
            image: '',
            rotationAlignment: 'viewport',
          },
          textOptions: {
            textField: ['get', 'stringName'],
            textFont: ['Open Sans SemiBold'],
            anchor: 'top',
            justify: 'left',
            color: '#ffffff',
            textAllowOverlap: true,
            ignorePlacement: true,
            rotationAlignment: 'viewport',
          },
          minZoom: textMinZoom,
          maxZoom: 20.5,
        }}
        type="SymbolLayer"
        events={layerEvents}
      />
    </AzureMapDataSourceProvider>
  )
}
