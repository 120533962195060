import styled from 'styled-components'

interface DivlinkProps {
  bgColor?: string
}

export const MarkerContainer = styled.div<DivlinkProps>`
  .pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: ${(props) => props.bgColor ?? '#00cae9'}; /* Cor dinâmica */
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
  }

  .pin:after {
    content: '';
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #e6e6e6;
    position: absolute;
    border-radius: 50%;
  }
`
