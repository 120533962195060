import { TablePagination } from '@mui/material'
import { useEffect, useState } from 'react'
import { useAuth } from '~/contexts/Auth'
import { SelectOption } from '~/pages/Dashboard/Reports/types'
import { getAnomalyListService, getAnomalyPageInfoService } from '~/services/Anomaly'
import { IAnomaliePageInfoProps, IAnomalyLIstProps } from '~/services/Anomaly/types'
import { AccessLevel } from '~/utils/General'
import ImageModal from './components/imageModal'
import RemoveAnomalies from './components/RemoveAnomalies'
import Search from './components/search'
import TableDetails from './components/tableDetails'
import * as S from './styles'

type AnomaliesDetailsProps = {
  selectedInspection: SelectOption
  handleShowDetails(id: string): void
  handleFetchData(value: boolean): void
  fetchDetailsData: boolean
  deleteAnomaly(anomaly: IAnomalyLIstProps): void
  removeAnomalies(anomalies: number[]): void
  inspectionId?: string
}

const AnomaliesDetails = ({
  selectedInspection,
  handleShowDetails,
  fetchDetailsData,
  handleFetchData,
  deleteAnomaly,
  removeAnomalies,
  inspectionId,
}: AnomaliesDetailsProps) => {
  const [anomalyList, setAnomalyList] = useState<IAnomalyLIstProps[]>([])
  const [anomalyPageInfo, setAnomalyPageInfo] = useState<IAnomaliePageInfoProps | null>(null)
  const [totalAnomalies, setTotalAnomalies] = useState(0)
  const [filterSearch, setFilterSearch] = useState('')
  const [imageDetails, setImageDetails] = useState<IAnomalyLIstProps | null>()
  const [page, setPage] = useState(0)
  const { user } = useAuth()

  const currentInspectionId = inspectionId ?? selectedInspection.value

  const handleCloseModal = () => {
    setImageDetails(null)
  }

  const getAnomalyPageInfo = async () => {
    try {
      const resp = await getAnomalyPageInfoService(currentInspectionId)
      if (resp.success) setAnomalyPageInfo(resp.data)
    } catch (error) {
      console.error(error)
    }
  }

  const getAnomalyList = async (inspectionId: string, searchFilter?: string) => {
    try {
      const response = await getAnomalyListService(inspectionId, searchFilter)
      if (response.success) {
        setAnomalyList(response.data)
        setTotalAnomalies(response.itemsTotal ?? 0)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
    getAnomalyList(currentInspectionId, `${filterSearch}&PageNumber=${newPage + 1}`)
  }

  const getAnomalyListWhenDelete = async () => {
    try {
      getAnomalyPageInfo()
      let tempSearchFilter = `${filterSearch}&PageNumber=${page + 1}`
      const response: any = await getAnomalyListService(currentInspectionId, tempSearchFilter)
      if (response.success) {
        if (response.pages <= page + 1) {
          setAnomalyList(response.data)
          setTotalAnomalies(response.itemsTotal ?? 0)
        } else {
          tempSearchFilter = `${filterSearch}&PageNumber=${response.pages}`
          const secondResponse = await getAnomalyListService(currentInspectionId, tempSearchFilter)
          if (secondResponse.success) {
            setAnomalyList(secondResponse.data)
            setTotalAnomalies(secondResponse.itemsTotal ?? 0)
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getAnomalyPageInfo()
    setPage(0)
    getAnomalyList(currentInspectionId)
  }, [currentInspectionId])

  useEffect(() => {
    if (fetchDetailsData) {
      getAnomalyPageInfo()
      getAnomalyListWhenDelete()
      handleFetchData(false)
    }
  }, [fetchDetailsData])

  return (
    <S.DetailsWrapper>
      <Search
        {...{
          anomalyPageInfo,
          setFilterSearch,
          getAnomalyList,
          currentInspectionId,
        }}
      />
      `
      {user?.accessLevel === AccessLevel.Master && (
        <RemoveAnomalies {...{ anomalyPageInfo, removeAnomalies }} />
      )}
      <S.ContentWrapper>
        <TableDetails
          {...{ anomalyList, anomalyPageInfo, page, setImageDetails, handleShowDetails, deleteAnomaly }}
        />
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={totalAnomalies}
          rowsPerPage={10}
          page={page}
          onPageChange={(event, page) => handleChangePage(page)}
        />
      </S.ContentWrapper>
      <ImageModal {...{ handleCloseModal, imageDetails, anomalyPageInfo }} isOpen={!!imageDetails?.id} />
    </S.DetailsWrapper>
  )
}
export default AnomaliesDetails
