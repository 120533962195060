// Função utilitária para validar se os pontos formam um quadrilátero válido
export const validateQuadrilateral = (points: [number, number][]) => {
  if (points.length !== 4) return false

  const uniquePointsStr = new Set(points.map((p) => `${p[0]},${p[1]}`))
  if (uniquePointsStr.size < 4) return false

  const area =
    0.5 *
    Math.abs(
      points[0][0] * (points[1][1] - points[3][1]) +
        points[1][0] * (points[2][1] - points[0][1]) +
        points[2][0] * (points[3][1] - points[1][1]) +
        points[3][0] * (points[0][1] - points[2][1]),
    )

  const areaThreshold = 1e-10
  return area > areaThreshold
}
